import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Zoom,
    Typography,
    Modal,
    Grid,
    Button,
    Backdrop,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// custom
import * as rtfUtil from "../../../util/textFieldRendering";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "85%",
        overflowY: "auto",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    buttonSuccess: {
        backgroundColor: "#56f500",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E0E0E0",
        },
    },
    buttonCancel: {
        backgroundColor: "#cfcfcf",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#c51162",
        },
    },
    paddedGrid: {
        padding: 3,
    },
}));

const ShiftUpdateModal = (props) => {
    const classes = useStyles();
    const renderTextField = rtfUtil.renderTextField;

    const offProd =
        props.shift !== undefined
            ? [...props.shift].filter((elem) => elem.name.includes("OffProd"))
            : [];
    const prod =
        props.shift !== undefined
            ? [...props.shift].filter((elem) => !elem.name.includes("OffProd"))
            : [];

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={props.onSubmit}>
                        <Typography variant="h6" gutterBottom>
                            Edit Daily Production
                        </Typography>
                        <Grid container spacing={1}>
                            {prod !== [] ? (
                                prod
                                    .filter(
                                        (shift) =>
                                            shift.name !== "id" &&
                                            shift.name !== "shiftDuration"
                                    )
                                    .map((shift, indx) => {
                                        return shift.name === "shiftStart" ||
                                            shift.name === "shiftEnd" ||
                                            shift.name === "lunchDuration" ? (
                                            <Grid item xs={6} key={"b" + indx}>
                                                {renderTextField(
                                                    shift,
                                                    indx,
                                                    props.onChange
                                                )}
                                            </Grid>
                                        ) : shift.name === "shiftDate" ? (
                                            <Grid item xs={6} key={"b" + indx}>
                                                {renderTextField(
                                                    shift,
                                                    indx,
                                                    props.onChange,
                                                    true
                                                )}
                                            </Grid>
                                        ) : shift.name === "comments" ? (
                                            <Grid item xs={12} key={"b" + indx}>
                                                {renderTextField(
                                                    shift,
                                                    indx,
                                                    props.onChange
                                                )}
                                            </Grid>
                                        ) : (
                                            <Grid item xs={4} key={"b" + indx}>
                                                {renderTextField(
                                                    shift,
                                                    indx,
                                                    props.onChange
                                                )}
                                            </Grid>
                                        );
                                    })
                            ) : (
                                <Grid item xs={12}>
                                    {" "}
                                    <Typography varient="h2">
                                        {" "}
                                        No Data{" "}
                                    </Typography>
                                </Grid>
                            )}
                            <Accordion className={classes.button}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel-header"
                                >
                                    <Typography varient="h2">
                                        {" "}
                                        Off Production Tasks
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={[
                                        classes.center,
                                        classes.button,
                                    ].join(" ")}
                                >
                                    <Grid container>
                                        {offProd !== [] ? (
                                            offProd.map((shift, indx) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={"q" + indx}
                                                    className={
                                                        classes.paddedGrid
                                                    }
                                                >
                                                    {renderTextField(
                                                        shift,
                                                        indx,
                                                        props.onChange
                                                    )}
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                {" "}
                                                <Typography varient="h2">
                                                    {" "}
                                                    No Data{" "}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={[
                                        classes.button,
                                        classes.buttonSuccess,
                                    ].join(" ")}
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    className={[
                                        classes.button,
                                        classes.buttonCancel,
                                    ].join(" ")}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default ShiftUpdateModal;
