import * as types from "./actionTypes";
import { LOG_OUT } from '../logout/actionTypes';

export default function userReducer(
  state = {
    user: undefined,
    userSuccess: false,
    userError: null
  },
  action
) {
  switch (action.type) {
    case types.USER:
      return {
        ...state,
        user: action.user
      };
    case types.USER_SUCCESS:
      return {
        ...state,
        userSuccess: action.userSuccess
      };
    case types.USER_ERROR:
      return {
        ...state,
        userError: action.userError
      };
    case LOG_OUT:
      return {
        ...state,
        user: null
      }
    default:
      return state;
  }
}

