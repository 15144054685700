import React from "react";
import cookie from "react-cookies";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Material UI Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
// Custom
import DrawerSubList from "./DrawerSubList";
import { logout } from "../../store/logout/logoutActions";

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: "flex",
        margin: "0 auto",
    },
    title: {
        fontSize: "1rem",
    },
    drawerPaper: {
        color: "#448AFF",
        background: "#fff",
        width: 240,
        display: "flex",
        alignItems: "center",
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerHeader: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        padding: "6px",
        justifyContent: "flex-end",
    },
    logo: {
        marginTop: "auto",
        marginBottom: "0.5rem",
    },
}));

const AppDrawer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user, pages } = useSelector((state) => ({
        user: state.userReducer.user,
        currentSite: state.sitesReducer.currentSite,
        token: state.authReducer.token,
        pages: state.pagesReducer.pages,
    }));
    const { drawerOpen, handleDrawerClose } = props;

    const handleLogout = async () => {
        handleDrawerClose(false);
        const token = await cookie.load("token");
        if (token) cookie.remove("token", { path: "/" });
        await dispatch(logout());
        props.history.push("/login");
    };

    if (!user) return null;
    const undefinedPages = pages === null || pages === undefined;

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            open={Boolean(drawerOpen)}
            classes={{ paper: classes.drawerPaper }}
            className={classes.drawer}
        >
            <div className={classes.drawerHeader}>
                <div className={classes.titleContainer}>
                    <Typography noWrap className={classes.title}>
                        Performance Tracking
                    </Typography>
                </div>
                <Tooltip title="Close Menu" placement="right">
                    <IconButton
                        onClick={() => handleDrawerClose(false)}
                        color="inherit"
                        style={{ border: "1px solid" }}
                        size="large"
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.drawerMenu}>
                <Divider style={{ background: "#555" }} />
                <List>
                    <DrawerSubList
                        handleDrawerClose={() => handleDrawerClose(false)}
                        pages={
                            !undefinedPages
                                ? [
                                      ...pages.filter(
                                          (p) =>
                                              p.cardText.split(" ")[0] ===
                                                  "Shift" ||
                                              p.cardText.includes("Production")
                                      ),
                                  ]
                                : []
                        }
                        logo="performance"
                        title="Performance Tracking"
                    />
                    <Divider style={{ background: "#555" }} />
                    {!undefinedPages &&
                    pages.filter((p) => p.secondaryText === "Maintenence") ? (
                        <React.Fragment>
                            <DrawerSubList
                                handleDrawerClose={() =>
                                    handleDrawerClose(false)
                                }
                                pages={
                                    !undefinedPages
                                        ? [
                                              ...pages.filter(
                                                  (p) =>
                                                      p.secondaryText ===
                                                      "Dashboard"
                                              ),
                                          ]
                                        : []
                                }
                                logo="dashboard"
                                title="Dashboard Panel"
                            />
                            <Divider style={{ background: "#555" }} />
                        </React.Fragment>
                    ) : null}
                    <DrawerSubList
                        handleDrawerClose={() => handleDrawerClose(false)}
                        pages={
                            !undefinedPages
                                ? [
                                      ...pages.filter(
                                          (p) =>
                                              p.secondaryText ===
                                                  "Maintenence" &&
                                              p.cardText !== "Shift"
                                      ),
                                  ]
                                : []
                        }
                        logo="admin"
                        title="Admin Panel"
                    />
                </List>
                <Divider style={{ background: "#555" }} />
                <List>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <PowerSettingsNew style={{ color: "#448AFF" }} />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography variant="button">
                                    Log Out
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </div>
            <div className={classes.logo}>{/* <Logo /> */}</div>
        </Drawer>
    );
};

export default withRouter(AppDrawer);
