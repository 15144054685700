import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Card,
    CardContent,
    TextField,
    IconButton,
    Grid,
    Tooltip,
    Grow,
} from "@mui/material";
// Material UI Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
    firstItem: {
        flexGrow: 1,
    },
    lastItem: {
        flexShrink: 1,
    },
    item: {
        justifyContent: "center",
        flexGrow: 1,
    },
    subItem: {
        padding: 5,
    },
    container: {
        display: "flex",
        flexGrow: 1,
    },
}));

const ShiftRangeToggle = (props) => {
    const classes = useStyles();
    const { startDate, endDate, custom } = props.filters;
    const { onChangeBack, onChangeForward, onChangeCustomDate } = props;

    return (
        <Grow in={true} timeout={500}>
            <Card>
                <CardContent>
                    <Grid container className={classes.container}>
                        <Grid item className={classes.firstItem}>
                            <Tooltip
                                title={"Go back a week"}
                                aria-label={"Go back a week"}
                            >
                                <IconButton
                                    disabled={custom}
                                    onClick={onChangeBack}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item className={classes.item}>
                            <TextField
                                className={classes.subItem}
                                variant="standard"
                                disabled
                                key={"A12"}
                                required
                                type="date"
                                name={"startDate"}
                                label="Start Date"
                                onChange={(e) =>
                                    onChangeCustomDate(e, "startDate")
                                }
                                value={
                                    startDate ||
                                    new Date().toISOString().split("T")[0]
                                }
                                InputLabelProps={{
                                    shrink: true,
                                    size: "small",
                                }}
                            />
                            <TextField
                                className={classes.subItem}
                                variant="standard"
                                disabled
                                key="SR71"
                                required
                                type="date"
                                name={"endDate"}
                                label="End Date"
                                onChange={(e) =>
                                    onChangeCustomDate(e, "endDate")
                                }
                                value={
                                    endDate ||
                                    new Date().toISOString().split("T")[0]
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item className={classes.lastItem}>
                            <Tooltip
                                title={"Go forward a week"}
                                aria-label={"Go forward a week"}
                            >
                                <IconButton
                                    disabled={custom}
                                    onClick={onChangeForward}
                                    size="large"
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grow>
    );
};

export default ShiftRangeToggle;
