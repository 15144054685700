import * as types from "./actionTypes";
import api from "../../api";
import { PAGES } from "../../api/routes";

export const setPages = (pages) => {
    return {
        type: types.PAGES,
        pages,
    };
};

const setPagesSuccess = (pagesSuccess) => {
    return {
        type: types.PAGES_SUCCESS,
        pagesSuccess,
    };
};

const setPagesError = (pagesError) => {
    return {
        type: types.PAGES_ERROR,
        pagesError,
    };
};

export const getPages = (roleId, token) => {
    return async (dispatch) => {
        try {
            const response = await api.get(`${PAGES}/${roleId}`, {
                headers: { Authorization: token },
            });
            dispatch(setPages(response.data));
            dispatch(setPagesSuccess(true));
        } catch (err) {
            dispatch(setPagesError(err));
        }
    };
};
