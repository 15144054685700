import * as types from './actionTypes';
import api from '../../api';
import { TASKS } from '../../api/routes';

export const setTasks = tasks => {
  return {
    type: types.TASKS,
    tasks
  };
};

const setTasksSuccess = tasksSuccess => {
  return {
    type: types.TASKS_SUCCESS,
    tasksSuccess
  };
};

const setTasksError = tasksError => {
  return {
    type: types.TASKS_ERROR,
    tasksError
  };
};

const sendTasksRequest = async (roleId, roleTasksTime, roleTasksOffProd, token) => {
        let tasks = await api.get(`${TASKS}/${roleId}`, { headers: {Authorization: token}});
        tasks = roleTasksTime.concat(tasks.data).concat(roleTasksOffProd);
        return tasks;
};

export const getTasks = (roleId, roleTasksTime, roleTasksOffProd, token) => {

  return async dispatch => {
    try {
      const tasks = await sendTasksRequest(roleId, roleTasksTime, roleTasksOffProd, token);
      dispatch(setTasks(tasks));
      dispatch(setTasksSuccess(true));
    } catch (err) {
      dispatch(setTasksError(err))
    }
  };
};