import * as types from "./actionTypes";

export default function rolesReducer(
  state = {
    roles: undefined,
    rolesSuccess: false,
    rolesError: null
  },
  action
) {
  switch (action.type) {
    case types.ROLES:
      return {
        ...state,
        roles: action.roles
      };
    case types.ROLES_SUCCESS:
      return {
        ...state,
        rolesSuccess: action.rolesSuccess
      };
    case types.ROLES_ERROR:
      return {
        ...state,
        rolesError: action.rolesError
      };
    default:
      return state;
  }
}
