
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Card, CardHeader, CardContent, ListItemSecondaryAction, ListItemText, List, ListItem, Grid, Button} from '@mui/material';
import  SaveIcon from '@mui/icons-material/Save';
import CustomSwitch from '../../Buttons/CustomSwitch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 512, // TODO: this is a hack, maybe make it dynamic
    backgroundColor: theme.palette.background.paper,
  },
  headingContainer: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'flex-start',
  },
  heading: {
      alignSelf: 'flexStart',
      padding: 10,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#33a3ff',
    color: 'white',
  },
  gridContainer: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: '#FFF',
    alignItems: 'center',
  },
  grid: {
      width: '100%',
  },
  card: {
      marginTop: 20,
  },
  cardHeaderTitle: {
    marginLeft: 10,
    marginTop: 5,
    fontWeight: 500,
  },
  cardHeader: {
      borderBottom: '1px solid #EEE',
  }
}));

const PageList = props => {
  const classes = useStyles();
  const [currentPages, setCurrentPages] = useState(props.currentPages);

  useEffect(() => {
    setCurrentPages(props.currentPages);
  }, [props.currentPages]);

  const handleToggle = (value) => () => {
    const contained = currentPages.filter(cp => cp.cardText === value.cardText && cp.secondaryText === value.secondaryText);
    let newCurrentPages = [...currentPages];

    if(contained.length === 0) {
        newCurrentPages.push(value);
    } else {
        newCurrentPages = currentPages.filter(cp => cp.cardText !== value.cardText || cp.secondaryText !== value.secondaryText);
    }

    setCurrentPages(newCurrentPages);
  };

  return (
      <Card className={classes.card}>
          <CardHeader 
              title="Page Access" 
              classes={{root: classes.cardHeader, title: classes.cardHeaderTitle }}
          />
          <CardContent>
          <Grid container
              className={classes.gridContainer}
              direction="column"
              alignItems="center"
              justifyContent="center"
          >
              <Grid item xs={12}>
                  <List className={classes.root}>
                  {props.allPages === null || props.allPages === undefined ? [] : props.allPages.map(page => (
                      <ListItem key={'A' + page.id}>
                          <ListItemText 
                              id={page.cardText + page.secondaryText} 
                              primary={page.cardText + " " + page.secondaryText} 
                          />
                          <ListItemSecondaryAction>
                                  <CustomSwitch 
                                  edge="end"
                                  onChange={handleToggle(page)}
                                  checked={currentPages.find(cp => cp.id === page.id) !== undefined}
                                  />
                          </ListItemSecondaryAction>
                      </ListItem>
                  ))}
                  </List>
              </Grid>
              <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                  <Grid item>
                      <Button
                          variant="contained"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={(e) => props.onSubmit(e, currentPages)}
                      >
                          Save
                      </Button>
                  </Grid>
              </Grid>
          </Grid>
          </CardContent>
      </Card>
  );
};

export default PageList;