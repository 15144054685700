import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "./store/auth/authReducer";
import sitesReducer from "./store/sites/sitesReducer";
import userReducer from "./store/user/userReducer";
import userInfoReducer from "./store/userInfo/userInfoReducer";
import tasksReducer from "./store/tasks/tasksReducer";
import pagesReducer from "./store/pages/pagesReducer";
import rolesReducer from "./store/roles/rolesReducer";
import { LOG_OUT } from "./store/logout/actionTypes";
import App from "./components/App";
// persistor
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
import "./util/polyfills";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from "@mui/material/styles";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers({
    sitesReducer,
    userReducer,
    userInfoReducer,
    authReducer,
    rolesReducer,
    tasksReducer,
    pagesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOG_OUT) state = undefined;

    return appReducer(state, action);
};

const persistedReducer = persistReducer(
    {
        key: "root",
        storage,
    },
    rootReducer
);

const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);
let persistor = persistStore(store);

const theme = createTheme({
    palette: {
        primary: {
            light: "#E0E0E0",
            main: "#448AFF",
            dark: "#E0E0E0",
        },
        secondary: {
            main: "#E0E0E0",
        },
    },
});

ReactDOM.render(
    <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </PersistGate>,

    document.getElementById("root")
);
