import React, { useState, useEffect, useCallback } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setToken } from "../store/auth/authActions";
import { getUser } from "../store/user/userActions";
import * as actionTypes from "../store/logout/actionTypes";
import axios from "axios";
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from "react-router-dom";
import { USERS } from "../api/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        width: `calc(100vw - ${240}px)`,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
}));

const PrivateRoute = ({ component: Component, ...rest }) => {
    const classes = useStyles();

    const { user } = useSelector(
        (state) => ({
            user: state.userReducer.user,
        }),
        shallowEqual
    );
    const token = useSelector((state) => state.authReducer.token);
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState(true);

    const fetchUser = useCallback(async () => {
        try {
            const headers = { headers: { Authorization: token } };
            const res = await axios.get(`${USERS}/${user.email}`, headers);
            setLoggedIn(res && res.status === 200);
        } catch (err) {
            dispatch(setToken(""));
            dispatch(getUser(undefined));
            dispatch({ type: actionTypes.LOG_OUT });
            setLoggedIn(false);
        }
    }, [token, dispatch, user]); // changed from user.email

    useEffect(() => {
        fetchUser();
    }, [token, fetchUser]);

    const location = useLocation();
    const history = useHistory();

    // if user gets stuck at empty path redirect... should only happen if url is manually manipulated.
    if (location.pathname === "/" && loggedIn)
        history.push("/daily-production");

    return (
        <Route
            {...rest}
            render={(props) =>
                user ? (
                    <div className={classes.root}>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            <Component {...props} />
                        </main>
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
