import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { Route, Switch, HashRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createBrowserHistory } from "history";

import "../index.css";
import Login from "./Login";
// import Home         from './Home';
import AppDrawer from "./Drawers/AppDrawer";
import Navbar from "./Navbar";
import PrivateRoute from "./PrivateRoute";

// Admin
import ManagerMaintenance from "./Admin/ManagerMaintenance/ManagerMaintenance";
import UserMaintenance from "./Admin/UserMaintenance";
// import SiteMaintenance  from './Admin/SiteMaintenance/SiteMaintenance';
import ShiftMaintenance from "./Admin/ShiftMaintenance";
import RoleMaintenance from "./Admin/RoleMaintenance/RoleMaintenance";
import DailyProduction from "./DailyProduction/DailyProduction";
import MonthlyLeader from "./Dashboards/MonthlyLeader/MonthlyLeader";
import MonthlyTasks from "./Dashboards/MonthlyTasks/MonthlyTasks";
import MonthToDate from "./Dashboards/MonthToDate/MonthToDate";
import MissingShift from "./Dashboards/MissingShift/MissingShift";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
}));

const history = createBrowserHistory();
const App = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuList, setMenuList] = useState(false);

    const user = useSelector((state) => state.userReducer.user);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <HashRouter basename="" history={history}>
                <Navbar
                    drawerOpen={drawerOpen}
                    user={user}
                    setDrawerOpen={() => setDrawerOpen(true)}
                />
                <AppDrawer
                    menuList={menuList}
                    drawerOpen={drawerOpen}
                    handleDrawerClose={() => setDrawerOpen(false)}
                    handleMenuItemClick={() => setMenuList(!menuList)}
                />
                <Switch>
                    <PrivateRoute exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    {/* <PrivateRoute exact path="/" component={Home} requireSite={true} /> */}
                    {/* <PrivateRoute exact path="/site-maintenance" component={SiteMaintenance}  /> */}
                    <PrivateRoute
                        exact
                        path="/missing-shift"
                        component={MissingShift}
                    />
                    <PrivateRoute
                        exact
                        path="/mgr-dashboard"
                        component={MonthlyLeader}
                    />
                    <PrivateRoute
                        exact
                        path="/mtd-dashboard"
                        component={MonthToDate}
                    />
                    <PrivateRoute
                        exact
                        path="/monthly-tasks"
                        component={MonthlyTasks}
                    />
                    <PrivateRoute
                        exact
                        path="/user-maintenance"
                        component={UserMaintenance}
                    />
                    <PrivateRoute
                        exact
                        path="/prod-details"
                        component={ShiftMaintenance}
                    />
                    <PrivateRoute
                        exact
                        path="/role-maintenance"
                        component={RoleMaintenance}
                    />
                    <PrivateRoute
                        exact
                        path="/daily-production"
                        component={DailyProduction}
                    />
                    <PrivateRoute
                        exact
                        path="/manager-maintenance"
                        component={ManagerMaintenance}
                    />
                </Switch>
            </HashRouter>
        </div>
    );
};

export default App;
