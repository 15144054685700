import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, Box } from "@mui/material";
// Material UI Icons
import { ToggleOff, ToggleOn, AddBox, Edit } from "@mui/icons-material/";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MuiAlert from "@mui/material/Alert";
// Custom
import { tableIcons } from "../../util/tableIcons";
import api from "../../api";
import { SHIFTS, USER_ROLE, FORMATTED_SHIFTS } from "../../api/routes";
import ShiftUpdateModal from "../Modals/ShiftMaintenanceModals/ShiftUpdateModal";
import ShiftCreateModal from "../Modals/ShiftMaintenanceModals/ShiftCreateModal";
import ShiftDeleteModal from "../Modals/ShiftMaintenanceModals/ShiftDeleteModal";
import * as rtfUtil from "../../util/textFieldRendering";
import * as dateUtils from "../../util/dateUtils/dateUtils";
import ShiftRangeToggle from "../DailyProduction/ShiftRangeToggle";
import * as mtExport from "../../util/materialTableExport";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
}));

const ShiftMaintenance = () => {
    const classes = useStyles();

    const { currentSite, user, token, tasks } = useSelector((state) => ({
        currentSite: state.sitesReducer.currentSite,
        user: state.userReducer.user,
        token: state.authReducer.token,
        tasks: state.tasksReducer.tasks,
    }));

    const [state, setState] = useState({
        columns: [],
        isLoading: false,
        isArchived: false,
        shiftAdded: false,
        shiftUpdated: false,
        shiftCreateError: false,
        shiftsArchivedError: false,
        shiftUpdateError: false,
        shiftUpdateModalOpen: false,
        shiftCreateModalOpen: false,
        showRowScores: false,
        newShift: [],
        shifts: [],
        shiftScores: [],
        formattedShifts: [],
        tasks: [],
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
    });

    const getShifts = useCallback(
        async (startDate, endDate) => {
            let shifts = await api.get(`${SHIFTS}/user/${currentSite.id}`, {
                headers: { Authorization: token },
                params: { startDate, endDate },
            });
            // format score as percentage
            shifts = shifts.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);

                    return elem;
                })
            );

            return shifts;
        },
        [token]
    );

    const getRawShifts = useCallback(
        async (startDate, endDate) => {
            let formattedShifts = await api.get(
                `${FORMATTED_SHIFTS}/user/raw/${currentSite.id}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            formattedShifts = formattedShifts.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);

                    return elem;
                })
            );

            return formattedShifts;
        },
        [token, state.serverUpdate]
    );

    const getScoreShifts = useCallback(
        async (startDate, endDate) => {
            let shiftScores = await api.get(
                `${FORMATTED_SHIFTS}/user/score/${currentSite.id}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            shiftScores = shiftScores.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);

                    return elem;
                })
            );

            return shiftScores;
        },
        [token]
    );

    const getUserRole = useCallback(
        async (usr, curSite) => {
            const userRole = await api.get(
                `${USER_ROLE}/user/${usr.id}/site/${curSite.id}`,
                { headers: { Authorization: token } }
            );
            // only set userRole if there is only 1... otherwise Roles have been misassigned
            if (userRole.data && userRole.data.length === 1) {
                return userRole.data[0];
            }
            return undefined;
        },
        [token]
    );

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));

        Promise.all([
            getShifts(state.filters.startDate, state.filters.endDate),
            getRawShifts(state.filters.startDate, state.filters.endDate),
            getScoreShifts(state.filters.startDate, state.filters.endDate),
            getUserRole(user, currentSite),
        ]).then((values) => {
            const bool = isArchived(values[0]);
            setState((prevState) => ({
                ...prevState,
                shifts: values[0],
                formattedShifts: values[1],
                shiftScores: values[2],
                userRole: values[3],
                isLoading: false,
                isArchived: bool,
            }));
        });
    }, [
        getShifts,
        getRawShifts,
        getScoreShifts,
        getUserRole,
        state.filters.startDate,
        state.filters.endDate,
    ]);

    /*
     * ===== SHIFT FILTER FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    //toggle score format display
    const toggleScore = async () => {
        setState((prevState) => ({
            ...prevState,
            showRowScores: !prevState.showRowScores,
        }));
    };

    // Snackbar Functions
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
        }));
    };

    const deleteShift = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await api.delete(`${SHIFTS}/${state.shiftToDelete.id}`, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                shifts: prevState.shifts.filter(
                    (shift, indx) => indx !== state.shiftToDelete.tableData.id
                ),
                shiftToDelete: undefined,
                shiftDeleted: true,
                isLoading: false,
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftDeleteError: true,
            }));
        }
    };

    /*
     * ===== GENERAL MODAL FUNCTIONS =====
     */
    const renderShiftUpdateModal = (rowData) => {
        const rowId = rowData.tableData.id;
        let selectedShiftForEdit;
        for (let i = 0; i < state.shifts.length; i++) {
            if (i === rowId) {
                selectedShiftForEdit = [...state.shifts[i]].filter(
                    (obj) => obj.name !== "score" && obj.name !== "userRoleId"
                );
                break;
            }
        }
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: true,
            selectedShiftForEdit: selectedShiftForEdit,
        }));
    };

    const handleUpdateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: false,
            selectedShiftForEdit: [],
        }));
    };

    const renderShiftCreateModal = () => {
        if (state.newShift.length > 0) {
            let tmpRole = [...tasks];
            const newShift = tmpRole.map((elem) => {
                return { ...elem, value: rtfUtil.getShiftDefaults(elem.type) };
            });
            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
                newShift,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
            }));
        }
    };

    const handleCreateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftCreateModalOpen: false,
        }));
    };

    /*
     * ===== UPDATE SHIFT MODAL FUNCTIONS =====
     */

    const onShiftUpdateSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftUpdateModalOpen: false,
        }));

        try {
            // add user_role_id
            const withUserRole = [...state.selectedShiftForEdit];
            withUserRole.push({
                name: "userRoleId",
                label: "User Role Id",
                type: "number",
                value: state.userRole.id,
            });
            let updatedShift = await api.put(
                `${SHIFTS}/${currentSite.id}`,
                withUserRole,
                { headers: { Authorization: token } }
            );
            updatedShift = updatedShift.data.map((elem) => {
                if (elem.name === "score")
                    elem.value = Math.round(elem.value * 100);

                return elem;
            });

            const oldShifts = [...state.shifts];
            // remove shifts that match the updated shiftId. then append the new shift
            const newShifts = oldShifts.filter((shft) => {
                const updatedIdAttr = updatedShift.find(
                    (attribs) => attribs.name === "id"
                );
                const exists = shft.find(
                    (attribs) =>
                        attribs.name === "id" &&
                        attribs.value === updatedIdAttr.value
                );
                return exists === undefined || exists === null;
            });
            newShifts.push(updatedShift);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdated: true,
                shifts: newShifts,
                serverUpdate: !prevState.serverUpdate,
                selectedShiftForEdit: [],
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdateError: true,
            }));
        }
    };

    const onShiftUpdateChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        // This precludes the use of '.' (i.e. no floats)
        if (
            (!Object.is(NaN, parseInt(value)) && value.includes(".")) ||
            value[0] === "."
        )
            return;

        const prevShift = [...state.selectedShiftForEdit].map((elem) => {
            return { ...elem };
        });
        for (let elem of prevShift) {
            if (elem.name === name) {
                elem.value = value;
                break;
            }
        }

        setState((prevState) => ({
            ...prevState,
            selectedShiftForEdit: prevShift,
        }));
    };

    const isArchived = (shifts) => {
        if (shifts.length <= 0) return false;
        else
            return (
                shifts[0].find((attr) => attr.name === "isApproved").value ===
                "true"
            );
    };
    /*
     * ===== CREATE SHIFT MODAL FUNCTIONS =====
     */
    const onShiftCreateChange = (e) => {
        setState((prevState) => {
            const name = e.target.name;
            const value = e.target.value;

            const newShift = [...prevState.newShift];
            for (let elem of newShift) {
                if (elem.name === name) {
                    elem.value = value;
                    break;
                }
            }
            return { ...prevState, newShift };
        });
    };

    const onShiftCreateSubmit = async (e, newShift) => {
        e.preventDefault();

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftCreateModalOpen: false,
        }));

            // add a dummy id so the server can process the shift.
            const shiftWithId = [
                { name: "id", type: "number", label: "ID", value: 0 },
                {
                    name: "isApproved",
                    type: "boolean",
                    label: "Is Approved",
                    value: false,
                },
                {
                    name: "userRoleId",
                    type: "number",
                    label: "User Role Id",
                    value: state.userRole.id,
                },
            ].concat(newShift);

            await api.post(
                `${SHIFTS}/${currentSite.id}`,
                shiftWithId,
                { headers: { Authorization: token } }
            ).then((resp) => {
                const shift = resp.data.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);
    
                    return elem;
                });
                const newShifts = [...state.shifts, shift];
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    shiftAdded: true,
                    shifts: newShifts,
                    serverUpdate: !prevState.serverUpdate,
                }));
            }).catch((resp) => {
                const shiftErrorKey = resp.message.includes("409") ? "shiftsArchivedError" : "shiftCreateError";
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    [shiftErrorKey]: true,
                }));
            });
    };

    const tableShifts = state.showRowScores
        ? [...state.shiftScores]
        : [...state.formattedShifts];

    const exportArray = 
        mtExport.exportCSVShift(
            tableShifts.length <= 0, 
            tableShifts, 
            "shift-maintenance" +
            state.filters.startDate.split("-")[1] +
            "-" +
            state.filters.startDate.split("-")[0]
        );

    return (
        <div className={classes.root}>
            <Box mt={1} mb={1}>
                <ShiftRangeToggle
                    filters={state.filters}
                    onChangeBack={onChangeBack}
                    onChangeForward={onChangeForward}
                />
            </Box>
            {state.isArchived ? (
                <Box mt={1} mb={1}>
                    <MuiAlert elevation={6} variant="filled" severity="info">
                        The Shifts for{" "}
                        {state.selectedRole && state.selectedRole.name
                            ? state.selectedRole.name
                            : ""}{" "}
                        have been archived for this month.
                    </MuiAlert>
                </Box>
            ) : null}
            <Box mt={1} mb={1}>
                {tasks === [] ? (
                    <div> Loading...</div>
                ) : (
                    <MaterialTable
                        title={`Daily Production Details`}
                        columns={
                            state.formattedShifts[0]
                                ? state.formattedShifts[0].map((shift) => ({
                                      title: shift.label,
                                      field: shift.name,
                                  }))
                                : []
                        }
                        data={tableShifts.map((arr) => {
                            let obj = {};
                            arr.forEach(
                                (col) =>
                                    (obj[col.name] = rtfUtil.tableRender(col))
                            );
                            return obj;
                        })}
                        isLoading={state.isLoading}
                        icons={tableIcons}
                        options={{
                            exportButton: true,
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: "#eee",
                                fontWeight: "bold",
                            },
                            exportMenu: exportArray
                        }}
                        actions={[
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <Edit {...props} ref={ref} />
                                )),
                                tooltip: "Edit Daily Production",
                                onClick: (e, rowData) =>
                                    renderShiftUpdateModal(rowData),
                            },
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <DeleteOutlinedIcon />
                                )),
                                tooltip: "Delete Daily Production",
                                onClick: (e, rowData) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        shiftToDelete: rowData,
                                    })),
                            },
                            {
                                icon: forwardRef((props, ref) =>
                                    state.showRowScores ? (
                                        <ToggleOff {...props} ref={ref} />
                                    ) : (
                                        <ToggleOn {...props} ref={ref} />
                                    )
                                ),
                                tooltip: state.showRowScores
                                    ? "Show Raw Data"
                                    : "Show Scores",
                                onClick: (e) => toggleScore(),
                                isFreeAction: true,
                            },
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <AddBox {...props} ref={ref} />
                                )),
                                tooltip: "Add Daily Production",
                                onClick: (e) => renderShiftCreateModal(),
                                isFreeAction: true,
                            },
                        ]}
                    />
                )}
            </Box>
            <ShiftUpdateModal
                open={state.shiftUpdateModalOpen}
                onClose={handleUpdateModalClose}
                onChange={onShiftUpdateChange}
                shift={state.selectedShiftForEdit}
                onSubmit={onShiftUpdateSubmit}
            />
            <ShiftCreateModal
                open={state.shiftCreateModalOpen}
                onClose={handleCreateModalClose}
                onChange={onShiftCreateChange}
                newShift={state.newShift}
                shifts={state.shifts}
                roleTasks={state.columns}
                tasks={tasks}
                onSubmit={onShiftCreateSubmit}
            />
            <ShiftDeleteModal
                open={state.shiftToDelete !== undefined}
                shift={state.shiftToDelete}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        shiftToDelete: undefined,
                    }))
                }
                onSubmit={deleteShift}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.shiftDeleted ||
                    state.shiftUpdated ||
                    state.shiftAdded ||
                    state.shiftDeleteError ||
                    state.shiftUpdateError ||
                    state.shiftCreateError ||
                    state.shiftsArchivedError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted
                            ? "success"
                            : state.shiftUpdateError || state.shiftCreateError || state.shiftsArchivedError
                            ? "error"
                            : undefined
                    }
                    open={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleteError ||
                        state.shiftUpdateError ||
                        state.shiftCreateError ||
                        state.shiftsArchivedError
                    }
                    style={{ zIndex: 2500 }}
                >
                    {state.shiftUpdated
                        ? "Daily Production Updated"
                        : state.shiftAdded
                        ? "Daily Production Added"
                        : state.shiftDeleted
                        ? "Daily Production Deleted"
                        : state.shiftDeleteError
                        ? "Error Deleting Daily Production"
                        : state.shiftCreateError
                        ? "Error Creating Daily Production"
                        : state.shiftsArchivedError
                        ? "Cannot create shift for archived Month"
                        : state.shiftUpdateError
                        ? "Error Updating Daily Production"
                        : null}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default ShiftMaintenance;
