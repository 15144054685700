/* ADMIN */
export const ADMIN = "api/v0";

/* SITES */
export const SITES = `${ADMIN}/site`;

/* SHIFTS */
export const SHIFTS = `${ADMIN}/shift`;

/* FORMATTED SHIFTS */
export const FORMATTED_SHIFTS = `${ADMIN}/formatShift`;

/* DASHBOARD DATA */
export const DASHBOARD_DATA = `${ADMIN}/dashboard`;

/* ROLES */
export const ROLES = `${ADMIN}/role`;

/* PAGES */
export const PAGES = `${ADMIN}/page`;

/* USERS */
export const USERS = `${ADMIN}/user`;

/* USER_ROLES */
export const USER_ROLE = `${ADMIN}/userRole`;

/* TASKS */
export const TASKS = `${ADMIN}/task`;

/* FETCH */
export const FETCH = `${ADMIN}/fetch`;

/* ARCHIVE */
export const ARCHIVE = `${ADMIN}/shift/archive`
