import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, Box } from "@mui/material";
// Material UI Icons
import { ToggleOff, ToggleOn } from "@mui/icons-material/";
import MuiAlert from "@mui/material/Alert";
// Custom
import UserDashboard from "../../DailyProduction/UserDashboard";
import { tableIcons } from "../../../util/tableIcons";
import api from "../../../api";
import { USER_ROLE, FORMATTED_SHIFTS, USERS } from "../../../api/routes";
import * as rtfUtil from "../../../util/textFieldRendering";
import * as dateUtils from "../../../util/dateUtils/dateUtils";
import * as mtExport from "../../../util/materialTableExport";
import * as models from "../../../util/models";
import MonthToDatePageControl from "./MonthToDatePageControl";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
}));

const MonthToDate = () => {
    const classes = useStyles();
    const { token, currentSite, roles } = useSelector((state) => ({
        token: state.authReducer.token,
        currentSite: state.sitesReducer.currentSite,
        roles: state.rolesReducer.roles,
    }));

    const [state, setState] = useState({
        columns: [],
        isLoading: false,
        isArchived: false,
        shiftAdded: false,
        shiftUpdated: false,
        monthArchived: false,
        shiftCreateError: false,
        shiftUpdateError: false,
        archiveMonthError: false,
        shiftUpdateModalOpen: false,
        shiftCreateModalOpen: false,
        archiveMonthModalOpen: false,
        showRowScores: false,
        newShift: [],
        shifts: [],
        shiftScores: [],
        formattedShifts: [],
        roles: [],
        users: [],
        selectedRole: undefined,
        selectedUser: undefined,
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
    });

    const getMTDPercentages = useCallback(
        async (startDate, endDate, roleId) => {
            let percentages = await await api.get(
                `${FORMATTED_SHIFTS}/role/percentages/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                    },
                }
            );
            setState((prevState) => ({
                ...prevState,
                datePercentages: percentages.data,
            }));
        },
        [token]
    );

    const getRolePercentages = useCallback(
        async (startDate, endDate, roleId) => {
            let percentages = await await api.get(
                `${FORMATTED_SHIFTS}/mtd/percentages/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                    },
                }
            );
            setState((prevState) => ({
                ...prevState,
                shiftPercentages: percentages.data,
            }));
        },
        [token]
    );

    const getRawShifts = useCallback(
        async (roleId, startDate, endDate) => {
            let formattedShifts = await api.get(
                `${FORMATTED_SHIFTS}/mtd/raw/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            formattedShifts = formattedShifts.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);

                    return elem;
                })
            );

            return formattedShifts;
        },
        [token, state.serverUpdate]
    );

    const getScoreShifts = useCallback(
        async (roleId, startDate, endDate) => {
            let shiftScores = await api.get(
                `${FORMATTED_SHIFTS}/mtd/score/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            shiftScores = shiftScores.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score")
                        elem.value = Math.round(elem.value * 100);

                    return elem;
                })
            );

            return shiftScores;
        },
        [token]
    );

    const getUserRole = useCallback(
        async (userId) => {
            const userRole = await api.get(
                `${USER_ROLE}/user/${userId}/site/${currentSite.id}`,
                { headers: { Authorization: token } }
            );
            // only set userRole if there is only 1... otherwise Roles have been misassigned
            if (userRole.data && userRole.data.length === 1) {
                return userRole.data[0];
            }
            return undefined;
        },
        [token]
    );

    const getUsers = useCallback(
        async (siteId) =>
            await api.get(`${USERS}/site/${siteId}`, {
                headers: { Authorization: token },
            }),
        [token]
    );

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const getData = async () => {
            const siteRoles = await models.getSiteRoles(token, currentSite.id);

            const selectedRole =
                state.selectedRole !== undefined
                    ? state.selectedRole
                    : siteRoles.data[0];

            // last promise, only retrieve's user role when filtering by user
            Promise.all([
                getRawShifts(
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                getScoreShifts(
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                getUsers(currentSite.id),
                state.selectedUser !== undefined &&
                state.selectedUser.fname !== "All" &&
                state.selectedUser.lname !== "Users"
                    ? getUserRole(state.selectedUser.id)
                    : null,
                getMTDPercentages(
                    state.filters.startDate,
                    state.filters.endDate,
                    selectedRole.id
                ),
                getRolePercentages(
                    state.filters.startDate,
                    state.filters.endDate,
                    selectedRole.id
                ),
            ]).then((values) => {
                setState((prevState) => {
                    const allUsers = { fname: "All", lname: "Users" };
                    if (
                        prevState.selectedUser !== undefined &&
                        prevState.selectedUser.fname !== "All" &&
                        prevState.selectedUser.lname !== "Users"
                    ) {
                        // const bool = isArchived(values[0]);
                        return {
                            ...prevState,
                            formattedShifts: values[0].filter(
                                (shft) =>
                                    shft.find((attr) => attr.name === "name")
                                        .value ===
                                    `${state.selectedUser.fname} ${state.selectedUser.lname}`
                            ),
                            shiftScores: values[1].filter(
                                (shft) =>
                                    shft.find((attr) => attr.name === "name")
                                        .value ===
                                    `${state.selectedUser.fname} ${state.selectedUser.lname}`
                            ),
                            roles: siteRoles.data,
                            users: [allUsers].concat(
                                values[2].data.sort((a, b) =>
                                    a.fname.localeCompare(b.fname)
                                )
                            ),
                            selectedUser: prevState.selectedUser,
                            selectedRole: selectedRole,
                            isLoading: false,
                            isArchived: false, // check if shifts have been archived
                        };
                    } else {
                        // const bool = isArchived(values[0]); //TODO: this needs updated
                        return {
                            ...prevState,
                            formattedShifts: values[0],
                            shiftScores: values[1],
                            roles: siteRoles.data,
                            users: [allUsers].concat(
                                values[2].data.sort((a, b) =>
                                    a.fname.localeCompare(b.fname)
                                )
                            ),
                            selectedUser: allUsers,
                            selectedRole: selectedRole,
                            isLoading: false,
                            isArchived: false, // check if shifts have been archived
                        };
                    }
                });
            });
        };
        getData();
    }, [
        getRawShifts,
        getScoreShifts,
        models.getSiteRoles,
        getMTDPercentages,
        getRolePercentages,
        state.filters.startDate,
        state.filters.endDate,
        state.serverUpdate,
        currentSite,
    ]);

    /*
     * ===== MANAGER PAGE CONTROL FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    // Role Toggle funciton
    const onChangeRole = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const newRole = e.target.value;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    // User Toggle funciton
    const onChangeUser = async (e) => {
        e.preventDefault();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const newUser = e.target.value;
        if (
            state.selectedUser.fname === newUser.fname &&
            state.selectedUser.lname === newUser.lname
        )
            return;

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedUser: prevState.users.find(
                (attr) =>
                    attr.fname === newUser.fname && attr.lname === newUser.lname
            ),
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    //toggle score format display
    const toggleScore = async () => {
        setState((prevState) => ({
            ...prevState,
            showRowScores: !prevState.showRowScores,
        }));
    };

    // Snackbar Functions
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            monthArchived: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            archiveMonthError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            monthArchived: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            archiveMonthError: false,
        }));
    };

    const tableShifts = state.showRowScores
        ? [...state.shiftScores]
        : [...state.formattedShifts];

    const exportArray = 
        mtExport.exportCSVShift(
            tableShifts.length <= 0, 
            tableShifts, 
            "month-to-date" +
            state.filters.startDate.split("-")[1] +
            "-" +
            state.filters.startDate.split("-")[0]
        );


    return (
        <div className={classes.root}>
            <Box mt={1} mb={1}>
                <MonthToDatePageControl
                    filters={state.filters}
                    onChangeBack={onChangeBack}
                    onChangeForward={onChangeForward}
                    roles={state.roles}
                    onChangeRole={onChangeRole}
                    selectedRole={state.selectedRole}
                    users={state.users}
                    onChangeUser={onChangeUser}
                    selectedUser={state.selectedUser}
                    isLoading={state.isLoading}
                />
            </Box>
            <Box mb={1}>
                <UserDashboard
                    datePercentages={state.datePercentages}
                    shiftPercentages={state.shiftPercentages}
                />
            </Box>
            {state.isArchived ? (
                <Box mt={1} mb={1}>
                    <MuiAlert elevation={6} variant="filled" severity="info">
                        The Shifts for{" "}
                        {state.selectedRole && state.selectedRole.name
                            ? state.selectedRole.name
                            : ""}{" "}
                        have been archived for this month.
                    </MuiAlert>
                </Box>
            ) : null}
            <Box mt={1} mb={1}>
                {state.roles === [] ? (
                    <div> Loading...</div>
                ) : (
                    <MaterialTable
                        title={`Month To Date Dashboard`}
                        columns={
                            state.formattedShifts[0]
                                ? state.formattedShifts[0].map((shift) => ({
                                      title: shift.label,
                                      field: shift.name,
                                  }))
                                : []
                        }
                        data={tableShifts.map((arr) => {
                            let obj = {};
                            arr.forEach(
                                (col) =>
                                    (obj[col.name] = rtfUtil.tableRender(col))
                            );
                            return obj;
                        })}
                        isLoading={state.isLoading}
                        icons={tableIcons}
                        options={{
                            exportButton: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, 100],
                            headerStyle: {
                                backgroundColor: "#eee",
                                fontWeight: "bold",
                            },
                            exportMenu: exportArray
                        }}
                        actions={[
                            {
                                icon: forwardRef((props, ref) =>
                                    state.showRowScores ? (
                                        <ToggleOff {...props} ref={ref} />
                                    ) : (
                                        <ToggleOn {...props} ref={ref} />
                                    )
                                ),
                                tooltip: state.showRowScores
                                    ? "Show Raw Data"
                                    : "Show Scores",
                                onClick: (e) => toggleScore(),
                                isFreeAction: true,
                            },
                        ]}
                    />
                )}
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={false}
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={false ? "success" : false ? "error" : undefined}
                    open={false}
                    style={{ zIndex: 2500 }}
                >
                    {state.shiftUpdated ? "Daily Production Updated" : null}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default MonthToDate;
