import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Material UI Core Components
import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
// Material UI Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const DrawerSubList = (props) => {
    const { handleDrawerClose } = props;
    let [menuList, setMenuList] = useState(false);
    const color = menuList ? "#ff6044" : "#448AFF";

    return (
        <>
            <ListItem button onClick={() => setMenuList(!menuList)}>
                <ListItemIcon>
                    {props.logo === "performance" ? (
                        <TrendingUpIcon style={{ color }} />
                    ) : props.logo === "dashboard" ? (
                        <DashboardIcon style={{ color }} />
                    ) : (
                        <SupervisorAccountIcon style={{ color }} />
                    )}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="button">{props.title}</Typography>
                    }
                />
                {menuList ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={menuList} timeout="auto" unmountOnExit>
                <List compnent="div" disablePadding>
                    {props.pages === undefined && props.pages.length === 0
                        ? []
                        : props.pages.map((screen, i) => (
                              <ListItem
                                  key={i}
                                  button
                                  component={NavLink}
                                  onClick={handleDrawerClose}
                                  to={screen.endpoint}
                                  // activeClassName="Mui-selected"
                                  exact
                              >
                                  <ListItemText
                                      disableTypography
                                      primary={
                                          <Badge color={"primary"}>
                                              <Typography variant="overline">
                                                  {screen.cardText +
                                                      " " +
                                                      screen.secondaryText}
                                              </Typography>
                                          </Badge>
                                      }
                                      style={{
                                          paddingLeft: "1rem",
                                          color: "#448AFF",
                                      }}
                                  />
                              </ListItem>
                          ))}
                </List>
            </Collapse>
        </>
    );
};

export default DrawerSubList;
