import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, Box } from "@mui/material";
// Material UI Icons
import { ToggleOff, ToggleOn, AddBox, Edit } from "@mui/icons-material/";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MuiAlert from "@mui/material/Alert";
// Custom
import { getTasks } from "../../../store/tasks/tasksActions";
import {
    roleTasksTime,
    roleTasksOffProd,
} from "../../DailyProduction/DailyProduction";
import { tableIcons } from "../../../util/tableIcons";
import api from "../../../api";
import {
    SHIFTS,
    USER_ROLE,
    FORMATTED_SHIFTS,
    ROLES,
    USERS,
} from "../../../api/routes";
import ShiftUpdateModal from "../../Modals/ManagerMaintenanceModals/ShiftUpdateModal";
import ShiftCreateModal from "../../Modals/ManagerMaintenanceModals/ShiftCreateModal";
import ShiftDeleteModal from "../../Modals/ManagerMaintenanceModals/ShiftDeleteModal";
import MonthArchiveModal from "../../Modals/ManagerMaintenanceModals/MonthArchiveModal";
import * as rtfUtil from "../../../util/textFieldRendering";
import * as dateUtils from "../../../util/dateUtils/dateUtils";
import * as mtExport from "../../../util/materialTableExport";
import ManagerPageControl from "./ManagerPageControl";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
}));

const ManagerMaintenance = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { token, tasks, currentSite } = useSelector((state) => ({
        token: state.authReducer.token,
        tasks: state.tasksReducer.tasks,
        currentSite: state.sitesReducer.currentSite,
    }));

    const [state, setState] = useState({
        columns: [],
        isLoading: false,
        isArchived: false,
        shiftAdded: false,
        shiftUpdated: false,
        monthArchived: false,
        shiftCreateError: false,
        shiftUpdateError: false,
        archiveMonthError: false,
        shiftUpdateModalOpen: false,
        shiftCreateModalOpen: false,
        archiveMonthModalOpen: false,
        showRowScores: false,
        newShift: [],
        shifts: [],
        shiftScores: [],
        formattedShifts: [],
        roles: [],
        users: [],
        selectedRole: undefined,
        selectedUser: undefined,
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
    });

    const getShifts = useCallback(
        async (roleId, startDate, endDate) => {
            let shifts = await api.get(`${SHIFTS}/role/${roleId}`, {
                headers: { Authorization: token },
                params: { startDate, endDate },
            });
            // format score as percentage
            shifts = shifts.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score") {
                        elem.value = Math.round(elem.value * 100);
                    }
                    return elem;
                })
            );

            return shifts;
        },
        [token]
    );

    const getRawShifts = useCallback(
        async (roleId, startDate, endDate) => {
            let formattedShifts = await api.get(
                `${FORMATTED_SHIFTS}/role/raw/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            formattedShifts = formattedShifts.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score") {
                        elem.value = Math.round(elem.value * 100);
                    }
                    return elem;
                })
            );

            return formattedShifts;
        },
        [token, state.serverUpdate]
    );

    const getScoreShifts = useCallback(
        async (roleId, startDate, endDate) => {
            let shiftScores = await api.get(
                `${FORMATTED_SHIFTS}/role/score/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate, endDate },
                }
            );
            // format score as percentage
            shiftScores = shiftScores.data.map((shift) =>
                shift.map((elem) => {
                    if (elem.name === "score") {
                        elem.value = Math.round(elem.value * 100);
                    }
                    return elem;
                })
            );

            return shiftScores;
        },
        [token]
    );

    const getUserRole = useCallback(
        async (userId) => {
            const userRole = await api.get(
                `${USER_ROLE}/user/${userId}/site/${currentSite.id}`,
                { headers: { Authorization: token } }
            );
            // only set userRole if there is only 1... otherwise Roles have been misassigned
            if (userRole.data && userRole.data.length === 1) {
                return userRole.data[0];
            }
            return undefined;
        },
        [token]
    );

    const getRoles = useCallback(
        async (siteId) =>
            await api.get(`${ROLES}/site/${siteId}`, {
                headers: { Authorization: token },
            }),
        [token]
    );

    const getUsers = useCallback(
        async (siteId) =>
            await api.get(`${USERS}/site/${siteId}`, {
                headers: { Authorization: token },
            }),
        [token]
    );

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const getData = async () => {
            const roles =
                state.roles.length > 0
                    ? state.roles
                    : (await getRoles(currentSite.id)).data;
            const selectedRole =
                state.selectedRole !== undefined
                    ? state.selectedRole
                    : roles[0];
            dispatch(
                getTasks(
                    selectedRole.id,
                    roleTasksTime,
                    roleTasksOffProd,
                    token
                )
            );

            // last promise, only retrieve's user role when filtering by user
            Promise.all([
                getShifts(
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                getRawShifts(
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                getScoreShifts(
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                getUsers(currentSite.id),
                state.selectedUser !== undefined &&
                state.selectedUser.fname !== "All" &&
                state.selectedUser.lname !== "Users"
                    ? getUserRole(state.selectedUser.id)
                    : null,
            ]).then((values) => {
                setState((prevState) => {
                    const allUsers = { fname: "All", lname: "Users" };
                    if (
                        prevState.selectedUser !== undefined &&
                        prevState.selectedUser.fname !== "All" &&
                        prevState.selectedUser.lname !== "Users"
                    ) {
                        const bool = isArchived(values[0]);
                        return {
                            ...prevState,
                            shifts: values[0].filter(
                                (shft) =>
                                    parseInt(
                                        shft.find(
                                            (attr) => attr.name === "userRoleId"
                                        ).value
                                    ) === values[4].id
                            ),
                            formattedShifts: values[1].filter(
                                (shft) =>
                                    shft.find((attr) => attr.name === "name")
                                        .value ===
                                    `${state.selectedUser.fname} ${state.selectedUser.lname}`
                            ),
                            shiftScores: values[2].filter(
                                (shft) =>
                                    shft.find((attr) => attr.name === "name")
                                        .value ===
                                    `${state.selectedUser.fname} ${state.selectedUser.lname}`
                            ),
                            roles: roles,
                            users: [allUsers].concat(
                                values[3].data.sort((a, b) =>
                                    a.fname.localeCompare(b.fname)
                                )
                            ),
                            selectedUser: prevState.selectedUser,
                            selectedRole: selectedRole,
                            isLoading: false,
                            isArchived: bool, // check if shifts have been archived
                        };
                    } else {
                        const bool = isArchived(values[0]);
                        return {
                            ...prevState,
                            shifts: values[0],
                            formattedShifts: values[1],
                            shiftScores: values[2],
                            roles: roles,
                            users: [allUsers].concat(
                                values[3].data.sort((a, b) =>
                                    a.fname.localeCompare(b.fname)
                                )
                            ),
                            selectedUser: allUsers,
                            selectedRole: selectedRole,
                            isLoading: false,
                            isArchived: bool, // check if shifts have been archived
                        };
                    }
                });
            });
        };
        getData();
    }, [
        getShifts,
        getRawShifts,
        getScoreShifts,
        getRoles,
        state.filters.startDate,
        state.filters.endDate,
        state.serverUpdate,
        currentSite,
    ]);

    /*
     * ===== MANAGER PAGE CONTROL FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    // Role Toggle funciton
    const onChangeRole = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const newRole = e.target.value;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    // User Toggle funciton
    const onChangeUser = async (e) => {
        e.preventDefault();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const newUser = e.target.value;
        if (
            state.selectedUser.fname === newUser.fname &&
            state.selectedUser.lname === newUser.lname
        )
            return;

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedUser: prevState.users.find(
                (attr) =>
                    attr.fname === newUser.fname && attr.lname === newUser.lname
            ),
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    //toggle score format display
    const toggleScore = async () => {
        setState((prevState) => ({
            ...prevState,
            showRowScores: !prevState.showRowScores,
        }));
    };

    // Snackbar Functions
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            monthArchived: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
            archiveMonthError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftDeleted: false,
            shiftUpdated: false,
            shiftAdded: false,
            monthArchived: false,
            shiftDeleteErrorError: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
            archiveMonthError: false,
        }));
    };

    const deleteShift = async (e) => {
        e.preventDefault();
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await api.delete(`${SHIFTS}/${state.shiftToDelete.id}`, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                shifts: prevState.shifts.filter(
                    (shift, indx) => indx !== state.shiftToDelete.tableData.id
                ),
                shiftToDelete: undefined,
                shiftDeleted: true,
                isLoading: false,
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftDeleteError: true,
            }));
        }
    };

    const archiveMonthSubmit = async () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            archiveMonthModalOpen: false,
        }));
        try {
            await api.get(`${SHIFTS}/archive/${state.selectedRole.id}`, {
                headers: { Authorization: token },
                params: {
                    startDate: state.filters.startDate,
                    endDate: state.filters.endDate,
                },
            });
            setState((prevState) => ({
                ...prevState,
                monthArchived: true,
                isLoading: false,
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                archiveMonthError: true,
                isLoading: false,
            }));
        }
    };

    /*
     * ===== GENERAL MODAL FUNCTIONS =====
     */
    const renderShiftUpdateModal = (rowData) => {
        const shiftId = rowData.id;
        let selectedShiftForEdit;
        for (let i = 0; i < state.shifts.length; i++) {
            if (
                shiftId ===
                state.shifts[i].find((attr) => attr.name === "id").value
            ) {
                selectedShiftForEdit = [...state.shifts[i]].filter(
                    (obj) => obj.name !== "score"
                );
                break;
            }
        }
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: true,
            selectedShiftForEdit: selectedShiftForEdit,
        }));
    };

    const handleUpdateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: false,
            selectedShiftForEdit: undefined,
        }));
    };

    const renderShiftCreateModal = () => {
        if (state.newShift.length > 0) {
            let tmpRole = [...tasks];
            const newShift = tmpRole.map((elem) => {
                return { ...elem, value: rtfUtil.getShiftDefaults(elem.type) };
            });

            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
                newShift,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
            }));
        }
    };

    const handleCreateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftCreateModalOpen: false,
        }));
    };

    /*
     * ===== UPDATE SHIFT MODAL FUNCTIONS =====
     */

    const onShiftUpdateSubmit = async (e, shift, user) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftUpdateModalOpen: false,
        }));

        try {
            const userObj = state.users.find(
                (u) => `${u.fname} ${u.lname}` === user
            );
            const userRole = await getUserRole(userObj.id);

            // add user_role_id
            const withUserRole = [...shift];
            withUserRole.push({
                name: "userRoleId",
                label: "User Role Id",
                type: "number",
                value: userRole.id,
            });
            let updatedShift = await api.put(
                `${SHIFTS}/${currentSite.id}`,
                withUserRole,
                {
                    headers: { Authorization: token },
                    params: { userId: userRole.userId },
                }
            );
            updatedShift = updatedShift.data.map((obj) => {
                if (obj.name === "score") {
                    obj.value = Math.round(obj.value * 100);
                }
                return obj;
            });

            const oldShifts = [...state.shifts];
            // remove shifts that match the updated shiftId. then append the new shift
            const newShifts = oldShifts.filter((shft) => {
                const updatedIdAttr = updatedShift.find(
                    (attribs) => attribs.name === "id"
                );
                const exists = shft.find(
                    (attribs) =>
                        attribs.name === "id" &&
                        attribs.value === updatedIdAttr.value
                );
                return exists === undefined || exists === null;
            });
            newShifts.push(updatedShift);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdated: true,
                shifts: newShifts,
                serverUpdate: !prevState.serverUpdate,
                selectedShiftForEdit: undefined,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdateError: true,
            }));
        }
    };

    const isArchived = (shifts) => {
        if (shifts.length <= 0) return false;
        else
            return (
                shifts[0].find((attr) => attr.name === "isApproved").value ===
                "true"
            );
    };

    /*
     * ===== CREATE SHIFT MODAL FUNCTIONS =====
     */
    const onShiftCreateSubmit = async (e, newShift, userId) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftCreateModalOpen: false,
        }));

        const userRole = await getUserRole(userId);
        // add a dummy id so the server can process the shift.
        const shiftWithId = [
            { name: "id", type: "number", label: "ID", value: "0" },
            {
                name: "isApproved",
                type: "boolean",
                label: "Is Approved",
                value: false,
            },
            {
                name: "userRoleId",
                type: "number",
                label: "User Role Id",
                value: userRole.id,
            },
        ].concat(newShift);

        await api.post(
            `${SHIFTS}/${currentSite.id}`,
            shiftWithId,
            {
                headers: { Authorization: token },
                params: { userId: userRole.userId },
            }
        ).then((resp) => {
            const shift = resp.data.map((obj) => {
                if (obj.name === "score") {
                    obj.value = Math.round(obj.value * 100);
                }
                return obj;
            });
            const newShifts = [...state.shifts, shift];
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftAdded: true,
                shifts: newShifts,
                serverUpdate: !prevState.serverUpdate,
            }));
        }).catch((resp) => {
            const shiftErrorKey = resp.message.includes("409") ? "shiftsArchivedError" : "shiftCreateError";
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                [shiftErrorKey]: true,
            }));
        });
    };

    const tableShifts = state.showRowScores
        ? [...state.shiftScores]
        : [...state.formattedShifts];

    const exportArray = 
    mtExport.exportCSVShift(
        tableShifts.length <= 0, 
        tableShifts, 
        "monthly-shifts-" +
        state.filters.startDate.split("-")[1] +
        "-" +
        state.filters.startDate.split("-")[0]
    );
 
    return (
        <div className={classes.root}>
            <Box mt={1} mb={1}>
                <ManagerPageControl
                    filters={state.filters}
                    onChangeBack={onChangeBack}
                    onChangeForward={onChangeForward}
                    roles={state.roles}
                    onChangeRole={onChangeRole}
                    selectedRole={state.selectedRole}
                    users={state.users}
                    onChangeUser={onChangeUser}
                    renderArchiveModal={() =>
                        setState((prevState) => ({
                            ...prevState,
                            archiveMonthModalOpen: true,
                        }))
                    }
                    selectedUser={state.selectedUser}
                    isArchived={state.isArchived}
                    isLoading={state.isLoading}
                />
            </Box>
            {state.isArchived ? (
                <Box mt={1} mb={1}>
                    <MuiAlert elevation={6} variant="filled" severity="info">
                        The Shifts for{" "}
                        {state.selectedRole && state.selectedRole.name
                            ? state.selectedRole.name
                            : ""}{" "}
                        have been archived for this month.
                    </MuiAlert>
                </Box>
            ) : null}
            <Box mt={1} mb={1}>
                {tasks === [] ? (
                    <div> Loading...</div>
                ) : (
                    <MaterialTable
                        title={`Manager Maintenance Production Tracking`}
                        columns={
                            state.formattedShifts[0]
                                ? state.formattedShifts[0].map((shift) => ({
                                      title: shift.label,
                                      field: shift.name,
                                  }))
                                : []
                        }
                        data={tableShifts.map((arr) => {
                            let obj = {};
                            arr.forEach(
                                (col) =>
                                    (obj[col.name] = rtfUtil.tableRender(col))
                            );
                            return obj;
                        })}
                        isLoading={state.isLoading}
                        icons={tableIcons}
                        options={{
                            exportButton: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, 100],
                            headerStyle: {
                                backgroundColor: "#eee",
                                fontWeight: "bold",
                            },
                            exportMenu: exportArray,
                        }}
                        actions={[
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <Edit {...props} ref={ref} />
                                )),
                                tooltip: "Edit Daily Production",
                                onClick: (e, rowData) =>
                                    renderShiftUpdateModal(rowData),
                            },
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <DeleteOutlinedIcon />
                                )),
                                tooltip: "Delete Daily Production",
                                onClick: (e, rowData) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        shiftToDelete: rowData,
                                    })),
                            },
                            {
                                icon: forwardRef((props, ref) =>
                                    state.showRowScores ? (
                                        <ToggleOff {...props} ref={ref} />
                                    ) : (
                                        <ToggleOn {...props} ref={ref} />
                                    )
                                ),
                                tooltip: state.showRowScores
                                    ? "Show Raw Data"
                                    : "Show Scores",
                                onClick: (e) => toggleScore(),
                                isFreeAction: true,
                            },
                            {
                                disabled: state.isArchived,
                                icon: forwardRef((props, ref) => (
                                    <AddBox {...props} ref={ref} />
                                )),
                                tooltip: "Add Daily Production",
                                onClick: (e) => renderShiftCreateModal(),
                                isFreeAction: true,
                            },
                        ]}
                    />
                )}
            </Box>
            <ShiftUpdateModal
                open={state.shiftUpdateModalOpen}
                onClose={handleUpdateModalClose}
                shift={state.selectedShiftForEdit}
                userShifts={state.formattedShifts}
                onSubmit={onShiftUpdateSubmit}
            />
            <ShiftCreateModal
                open={state.shiftCreateModalOpen}
                onClose={handleCreateModalClose}
                newShift={state.newShift}
                shifts={state.shifts}
                userShifts={state.formattedShifts}
                tasks={tasks}
                users={state.users}
                onSubmit={onShiftCreateSubmit}
            />
            <ShiftDeleteModal
                open={state.shiftToDelete !== undefined}
                shift={state.shiftToDelete}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        shiftToDelete: undefined,
                    }))
                }
                onSubmit={deleteShift}
            />
            <MonthArchiveModal
                open={state.archiveMonthModalOpen}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        archiveMonthModalOpen: false,
                    }))
                }
                onSubmit={archiveMonthSubmit}
                monthRange={state.filters}
                role={state.selectedRole}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.shiftDeleted        ||
                    state.shiftUpdated        ||
                    state.shiftAdded          ||
                    state.monthArchived       ||
                    state.shiftDeleteError    ||
                    state.shiftUpdateError    ||
                    state.shiftCreateError    ||
                    state.shiftsArchivedError ||
                    state.archiveMonthError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted ||
                        state.monthArchived
                            ? "success"
                            : state.shiftUpdateError    ||
                              state.shiftCreateError    ||
                              state.shiftsArchivedError ||
                              state.archiveMonthError   
                            ? "error"
                            : undefined
                    }
                    open={
                        state.shiftUpdated        ||
                        state.shiftAdded          ||
                        state.monthArchived       ||
                        state.shiftDeleteError    ||
                        state.shiftUpdateError    ||
                        state.shiftCreateError    ||
                        state.shiftsArchivedError ||
                        state.archiveMonthError
                    }
                    style={{ zIndex: 2500 }}
                >
                    {state.shiftUpdated
                        ? "Daily Production Updated"
                        : state.shiftAdded
                        ? "Daily Production Added"
                        : state.shiftDeleted
                        ? "Daily Production Deleted"
                        : state.monthArchived
                        ? "Daily Production Archived"
                        : state.shiftDeleteError
                        ? "Error Deleting Daily Production"
                        : state.shiftCreateError
                        ? "Error Creating Daily Production"
                        : state.shiftsArchivedError
                        ? "Cannot create shift for archived Month"
                        : state.shiftUpdateError
                        ? "Error Updating Daily Production"
                        : state.archiveMonthError
                        ? "Error Archiving Daily Production"
                        : null}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default ManagerMaintenance;
