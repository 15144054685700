// Base imports
import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import {
    Zoom,
    Typography,
    TextField,
    Select,
    Modal,
    MenuItem,
    InputLabel,
    Grid,
    FormHelperText,
    FormControlLabel,
    FormControl,
    Button,
    Backdrop,
    Checkbox,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        height: 600,
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    buttonSuccess: {
        backgroundColor: "#56f500",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E0E0E0",
        },
    },
    buttonCancel: {
        backgroundColor: "#cfcfcf",
        "&:hover": {
            cursor: "pointer",
            color: "#E0E0E0",
            backgroundColor: "#c51162",
        },
    },
}));

const UserListCreateModal = (props) => {
    const classes = useStyles();
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={props.onSubmit}>
                        <Typography variant="h6" gutterBottom>
                            Create User
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant="standard"
                                    fullWidth
                                    name="fname"
                                    label="First Name"
                                    placeholder="First Name"
                                    onChange={props.onChange}
                                    value={props.user.fname || ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="lname"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    onChange={props.onChange}
                                    value={props.user.lname || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Supervisor</InputLabel>
                                    <Select
                                        onChange={props.handleSupervisorChange}
                                        value={props.user.supervisor || ""}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                        }}
                                    >
                                        {props.supervisors
                                            .concat([
                                                {
                                                    id: 0,
                                                    fname: "None",
                                                    lname: "",
                                                    email: "",
                                                    passwordHash: "",
                                                    isActive: false,
                                                    supervisor: "",
                                                },
                                            ])
                                            ?.map(
                                                ({
                                                    id,
                                                    fname,
                                                    lname,
                                                    email,
                                                }) => (
                                                    <MenuItem
                                                        key={"B" + id}
                                                        value={email}
                                                    >
                                                        {fname} {lname}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    onChange={props.onChange}
                                    value={props.user.email || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="passwordHash"
                                    label="Password"
                                    placeholder="Password"
                                    onChange={props.onChange}
                                    value={props.user.passwordHash || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        required={true}
                                        name="role"
                                        onChange={props.onChange}
                                        value={props.user.role || ""}
                                    >
                                        {props.roles?.map(
                                            ({ id, name, siteId }) => (
                                                <MenuItem
                                                    key={"Q" + id}
                                                    value={id}
                                                >
                                                    {name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    <FormHelperText>Required</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="isActive"
                                            checked={
                                                props.user.isActive
                                                    ? true
                                                    : false
                                            }
                                            onChange={
                                                props.handleIsActiveChange
                                            }
                                        />
                                    }
                                    label="Is Active"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={[
                                        classes.button,
                                        classes.buttonSuccess,
                                    ].join(" ")}
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    className={[
                                        classes.button,
                                        classes.buttonCancel,
                                    ].join(" ")}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default UserListCreateModal;
