import * as types from './actionTypes';
import api from '../../api';
import { ROLES } from '../../api/routes';

export const setRoles = roles => {
  return {
    type: types.ROLES,
    roles
  };
};

const setRolesSuccess = rolesSuccess => {
  return {
    type: types.ROLES_SUCCESS,
    rolesSuccess
  };
};

const setRolesError = rolesError => {
  return {
    type: types.ROLES_ERROR,
    rolesError
  };
};


const sendRolesRequest = async (token, siteId) => await api.get(`${ROLES}/${siteId}`, { headers: {Authorization: token}});

export const getRoles = (token, siteId) => {
  return async dispatch => {
    try {
      const response = await sendRolesRequest(token, siteId);
      dispatch(setRoles(response.data));
      dispatch(setRolesSuccess(true));
    } catch (err) {
      dispatch(setRolesError(err))
    }
    
  };
};