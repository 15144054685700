import withStyles from '@mui/styles/withStyles';
import { Switch } from '@mui/material';

const CustomSwitch = withStyles((theme) => ({
    switchBase: {
      color: 'white',
      '&$checked': {
        color: '#33a3ff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#33a3ff',
          borderColor: '#33a3ff',
        },
      },
    },
    track: {
        opacity: 1,
        backgroundColor: theme.palette.grey[500],
        color: 'white',
    },
    checked: {},
  }))(Switch);

  export default CustomSwitch;