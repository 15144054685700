import { ExportCsv } from "@material-table/exporters";

const parseValue = (value) => isNaN(+value) ? value : +value;

export const exportCSVShift = (exportBool, allData, fileName) => {
    return exportBool ? []
            : [
                  {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                          cols.length <= 0 || datas.legnth <= 0
                              ? null
                              : ExportCsv(
                                    cols,
                                    allData
                                    .map(arr => arr.map(obj => parseValue(obj.value))),
                                    fileName
                                ),
                  },
              ];
};

export const exportCSV = (exportBool, allData, fileName) => {
    return exportBool ? []
            : [
                  {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                          cols.length <= 0 || datas.legnth <= 0
                              ? null
                              : ExportCsv(
                                    cols,
                                    allData.map(arr => arr.map(val => parseValue(val))),
                                    fileName
                                ),
                  },
              ];
};