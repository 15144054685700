export const monthMap = {
    1: 31,
    2: 28, // how to do leap day?
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
};

export const monthToInt = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
};

/** returns last day of the month for the given month
 * @param {Int} month 
 * @param {Int} year 
 * @returns {Int} day
 */
export const getDayFromMonth = (month, year) => {
  if(isLeapYear(year) && month === 2)
    return 29;
  else
    return monthMap[month];
}

export const intToMonth = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
};

export const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

// could use modulo and extra logic to remove some loops... idk that performance benefit is really worth it.
export const subtractMonths = (dateStr, months) => {
    let curDateStr = dateStr;
    let curMonth = +dateStr.split("-")[1]; //// TODO: was - 1
    let monthsCounter = months;

    while (monthsCounter > 12) {
        curDateStr = subtractYears(curDateStr, 1);
        monthsCounter -= 12;
    }

    while (monthsCounter > 0) {
        let yr = +curDateStr.split("-")[0];
        if (curMonth === 1) {
            curMonth = 12;
            monthsCounter--;
            curDateStr = `${--yr}-${curMonth}-${curDateStr.split("-")[2]}`;
        } else {
            curMonth--;
            monthsCounter--;
            const tmp = curMonth < 10 ? "0" + curMonth.toString() : curMonth;
            curDateStr = `${yr}-${tmp}-${curDateStr.split("-")[2]}`;
        }
    }
    return curDateStr;
};

export const addMonths = (dateStr, months) => {
    let curDateStr = dateStr;
    let curMonth = +dateStr.split("-")[1];
    let monthsCounter = months;

    while (monthsCounter >= 12) {
        curDateStr = addYears(curDateStr, 1);
        monthsCounter -= 12;
    }

    while (monthsCounter > 0) {
        let yr = +curDateStr.split("-")[0];
        if (curMonth === 12) {
            curMonth = 1;
            monthsCounter--;
            const monString =
                curMonth < 10 ? "0" + curMonth.toString() : curMonth;
            curDateStr = `${++yr}-${monString}-${curDateStr.split("-")[2]}`;
        } else {
            curMonth++;
            monthsCounter--;
            const tmp =
                curMonth < 10 ? "0" + curMonth.toString() : curMonth.toString();
            curDateStr = `${yr}-${tmp}-${curDateStr.split("-")[2]}`;
        }
    }
    return curDateStr;
};

export const addYears = (dateStr, years) => {
    const dateArr = dateStr.split("-");
    return `${+dateArr[0] + years}-${dateArr[1]}-${dateArr[2]}`;
};

export const subtractYears = (dateStr, years) => {
    const dateArr = dateStr.split("-");
    return `${+dateArr[0] - years}-${dateArr[1]}-${dateArr[2]}`;
};

export const subtractDays = (dateStr, days) => {
    let curDateStr = dateStr;
    let curDay = +dateStr.split("-")[2];
    let curMonth = +dateStr.split("-")[1];
    let curYear = +dateStr.split("-")[0];
    let dayCounter = days;

    while (dayCounter > 0) {
        if (curDay === 1) {
            if (curMonth === 1) {
                --dayCounter;
                curDateStr = `${+curDateStr.split("-")[0] - 1}-${12}-${
                    getDayFromMonth(12, curYear)
                    //monthMap[12]
                }`;
            } else {
                --curMonth;
                --dayCounter;
                curDay = getDayFromMonth(curMonth, curYear); //monthMap[curMonth];
                const monStr =
                    curMonth < 10
                        ? "0" + curMonth.toString()
                        : curMonth.toString();
                curDateStr = `${curDateStr.split("-")[0]}-${monStr}-${curDay}`;
            }
        } else {
            --dayCounter;
            --curDay;
            const monStr =
                curMonth < 10 ? "0" + curMonth.toString() : curMonth.toString();
            const dayStr =
                curDay < 10 ? "0" + curDay.toString() : curDay.toString();
            curDateStr = `${curDateStr.split("-")[0]}-${monStr}-${dayStr}`;
        }
    }
    return curDateStr;
};

export const addDays = (dateStr, days) => {
    let curDateStr = dateStr;
    let curDay = +dateStr.split("-")[2];
    let curMonth = +dateStr.split("-")[1];
    let curYear = +dateStr.split("-")[0];
    let dayCounter = days;

    while (dayCounter > 0) {
        if (curDay === getDayFromMonth(curMonth, curYear)) { //monthMap[curMonth]
            if (curMonth === 12) {
                --dayCounter;
                curDay = 1;
                curMonth = 1;
                curDateStr = `${+curDateStr.split("-")[0] + 1}-01-01`;
            } else {
                ++curMonth;
                --dayCounter;
                curDay = 1;
                const monStr =
                    curMonth < 10
                        ? "0" + curMonth.toString()
                        : curMonth.toString();
                const dayStr =
                    curDay < 10 ? "0" + curDay.toString() : curDay.toString();
                curDateStr = `${curDateStr.split("-")[0]}-${monStr}-${dayStr}`;
            }
        } else {
            --dayCounter;
            ++curDay;
            const monStr =
                curMonth < 10 ? "0" + curMonth.toString() : curMonth.toString();
            const dayStr =
                curDay < 10 ? "0" + curDay.toString() : curDay.toString();
            curDateStr = `${curDateStr.split("-")[0]}-${monStr}-${dayStr}`;
        }
    }
    return curDateStr;
};

export const isLeapYear = (year) => {
    if (year % 4 !== 0) return false;
    else if (year % 25 !== 0) return true;
    else if (year % 16 !== 0) return false;
    else return true;
};

export const shiftWeekForward = (curEndDateStr) => {
    return addDays(curEndDateStr, 7);
};

export const shiftWeekBack = (curStartDateStr) => {
    return subtractDays(curStartDateStr, 7);
};

export const shiftMonthForward = (curEndDateStr) => {
    return addMonths(curEndDateStr, 1);
};

export const shiftMonthBack = (curStartDateStr) => {
    return subtractMonths(curStartDateStr, 1);
};

export const shiftMonthForwardLastDay = (curStartDateStr) => {
    const nextDateArr = addMonths(curStartDateStr, 1).split("-");
    const curYear = +nextDateArr[0];
    const curMonth = +nextDateArr[1];
    nextDateArr.splice(2, 1);
    const month = getDayFromMonth(curMonth, curYear);
    return nextDateArr.join("-") + "-" + month;
};

export const shiftMonthBackLastDay = (curStartDateStr) => {
    const nextDateArr = subtractMonths(curStartDateStr, 1).split("-");
    const curYear = +nextDateArr[0];
    const curMonth = +nextDateArr[1];
    nextDateArr.splice(2, 1);
    const month = getDayFromMonth(curMonth, curYear);
    return nextDateArr.join("-") + "-" + month;
};

// dateStr takes a 'yyyy-mm-dd'
export const getLastDayOfMonth = (dateStr) => {
    const dateArr = dateStr.split("-");
    const curYear = +dateArr[0];
    const curMonth = +dateArr[1];
    dateArr.splice(2, 1);
    const month = getDayFromMonth(curMonth, curYear);
    return dateArr.join("-") + "-" + month;
};

// dateStr takes a 'yyyy-mm-dd'
export const getFirstDayOfMonth = (dateStr) => {
    const dateArr = dateStr.split("-");
    dateArr.splice(2, 1);
    return dateArr.join("-") + "-01";
};

// dateStr is 'yyyy-mm-dd'
export const getFirstDayOfYear = (dateStr) => `${dateStr.split("-")[0]}-01-01`;

// dateStr is 'yyyy-mm-dd'
export const getLastDayOfYear = (dateStr) => `${dateStr.split("-")[0]}-12-31`;
