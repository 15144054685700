import * as types from "./actionTypes";

export default function sitesReducer(
  state = {
    sites: undefined,
    sitesSuccess: false,
    sitesError: null,
    currentSite: undefined,
  },
  action
) {
  switch (action.type) {
    case types.SITES:
      return {
        ...state,
        sites: action.sites
      };
    case types.SITES_SUCCESS:
      return {
        ...state,
        sitesSuccess: action.sitesSuccess
      };
    case types.SITES_ERROR:
      return {
        ...state,
        sitesError: action.sitesError
      };
    case types.CURRENT_SITE:
      return {
        ...state,
        currentSite: action.currentSite
      };
    default:
      return state;
  }
}
