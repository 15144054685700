import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Grow, Snackbar, Box } from "@mui/material";
// Material UI Icons
import MuiAlert from "@mui/material/Alert";
// Custom
import { tableIcons } from "../../../util/tableIcons";
import api from "../../../api";
import { DASHBOARD_DATA, FORMATTED_SHIFTS } from "../../../api/routes";
// Custom JSX components
import MonthlyTasksControl from "./MonthlyTasksControl";
import * as mtExport from "../../../util/materialTableExport";
import * as dateUtils from "../../../util/dateUtils/dateUtils";
import * as models from "../../../util/models";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
    container: {
        marginTop: 50,
    },
}));


const MonthlyTasks = () => {
    const classes = useStyles();
    const { roles, token, tasks, currentSite } = useSelector((state) => ({
        roles: state.rolesReducer.roles,
        token: state.authReducer.token,
        tasks: state.tasksReducer.tasks,
        currentSite: state.sitesReducer.currentSite,
    }));

    const [state, setState] = useState({
        isLoading: false,
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
        roles: [],
        tableData: [],
    });

    const getNestedScoresCallback = useCallback(
        async (startDate, endDate, roleId) => {
            let response = await api.get(
                `${DASHBOARD_DATA}/role/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate: startDate, endDate: endDate },
                }
            );
            return response.data;
        },
        [token]
    );

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        // needed this hack to pass current state to getShifts, it was receiving previous state.
        const fetchAPI = async () => {
            const siteRoles = await models.getSiteRoles(token, currentSite.id);
            const selectedRole =
                state.selectedRole !== undefined
                    ? state.selectedRole
                    : siteRoles.data[0];

            const nestedData = await getNestedScoresCallback(
                state.filters.startDate, 
                state.filters.endDate,
                selectedRole.id
            );

            setState((prevState) => ({
                ...prevState,
                tableData: nestedData,
                roles: siteRoles.data,
                selectedRole,
            }));
        };
        fetchAPI();

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    }, [
        state.filters.startDate,
        state.filters.endDate,
        tasks,
        state.serverUpdate,
    ]);

    /*
     * ===== SHIFT FILTER FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    // Role Toggle funciton
    const onChangeRole = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const newRole = e.target.value;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    // Snackbar Function
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const tableRef = useRef();

    const columns = state.tableData.length > 0?
                 Object.keys(state.tableData[0])
                 .map(k => {
                    if(k === "name"){
                        return {"title": k, "field": k}
                    }
                    else{
                        return {"title": k.split('-')[2].trim(), "field": k.trim()}
                    }
                 })
                 : [];

    const copyTableData = ( data ) => {
        return data.map(obj => {
            if(obj.parentId === undefined){
                return {...obj, tableData: { isTreeExpanded: true }};
            } else {
                return {...obj}
            }
        })
    };
    const tableData = state.tableData.length > 0 ? copyTableData(state.tableData) : [];
    const exportData = [...state.tableData].map(Object.values);
    
    const exportArray = 
    mtExport.exportCSV(
        tableData.length <= 0, 
        exportData, 
        "monthly-tasks-" +
        state.filters.startDate.split("-")[1] +
        "-" +
        state.filters.startDate.split("-")[0]
    );

    return (
        <>
            <Container className={classes.container}>
                <Box mt={1} mb={1}>
                    <MonthlyTasksControl
                        filters={state.filters}
                        onChangeBack={onChangeBack}
                        onChangeForward={onChangeForward}
                        roles={state.roles}
                        onChangeRole={onChangeRole}
                        selectedRole={state.selectedRole}
                    />
                </Box>
                <Grow in={true} timeout={500}>
                    {tasks === [] ? (
                        <div> Loading...</div>
                    ) : (
                        <Box mb={1}>
                            <MaterialTable
                                tableRef={tableRef}
                                title='Monthly Scores and Tasks'
                                columns={columns}
                                data={tableData}
                                parentChildData={(row, rows) => rows.find((a) => a.name === row.parentId)}
                                isLoading={state.isLoading}
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    headerStyle: {
                                        backgroundColor: "#eee",
                                        fontWeight: "bold",
                                    },
                                    exportMenu: exportArray,
                                }}
                            />
                        </Box>
                    )}
                </Grow>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.shiftUpdated ||
                    state.shiftAdded ||
                    state.shiftDeleted ||
                    state.shiftUpdateError ||
                    state.shiftCreateError ||
                    state.shiftDeleteError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
                classes={{ root: { zIndex: 2500 } }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    open={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted ||
                        state.shiftUpdateError ||
                        state.shiftCreateError ||
                        state.shiftDeleteError
                    }
                    severity={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted
                            ? "success"
                            : state.shiftUpdateError ||
                              state.shiftCreateError ||
                              state.shiftDeleteError
                            ? "error"
                            : undefined
                    }
                    style={{ zIndex: 2500 }}
                    classes={{ root: { zIndex: 2500 } }}
                >
                    {state.shiftUpdated
                        ? "Daily Production Updated"
                        : state.shiftAdded
                        ? "Daily Production Added"
                        : state.shiftDeleted
                        ? "Daily Production Deleted"
                        : state.shiftCreateError
                        ? "Error Creating Daily Production"
                        : state.shiftUpdateError
                        ? "Error Updating Daily Production"
                        : state.shiftDeleteError
                        ? "Error Deleting Daily Production"
                        : null}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default MonthlyTasks;
