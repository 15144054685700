import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Zoom,
    Typography,
    Select,
    Modal,
    MenuItem,
    InputLabel,
    Grid,
    FormControl,
    Button,
    Backdrop,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        height: 200,
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
        backgroundColor: "#F50057",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#c51162",
        },
    },
    submitButton: {
        width: "100%",
    },
}));

const ToggleSiteModal = (props) => {
    const classes = useStyles();
    const [siteName, setSiteName] = useState(props.currentSite?.name);

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form
                        onSubmit={() =>
                            props.onSubmit(
                                props.sites.find(
                                    (site) => site.name === siteName
                                )
                            )
                        }
                    >
                        <Typography variant="h6" gutterBottom>
                            Change Site
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="agent-simple">
                                        Site
                                    </InputLabel>
                                    <Select
                                        value={siteName}
                                        onChange={(e) =>
                                            setSiteName(e.target.value)
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                        }}
                                    >
                                        {props.sites && props.sites.length > 0
                                            ? props.sites.map((value) => {
                                                  return (
                                                      <MenuItem
                                                          key={"B47" + value.id}
                                                          value={value.name}
                                                      >
                                                          {value.name}
                                                      </MenuItem>
                                                  );
                                              })
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={classes.submitButton}
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default ToggleSiteModal;
