import * as dateUtils from "../dateUtils/dateUtils";

// this converts shifts into the line graph of percentages. used in DailyProduction
export const shiftToLineGraph = (shiftList) => {
    if (shiftList.length <= 0) return [];

    // get number of days in month. Map shift day of month to array indeces
    const daysInMonth =
        dateUtils.monthMap[+shiftList[0][0].value.split("-")[1]];
    const dayMap = shiftList
        .map((elem, i) => {
            return { key: parseInt(elem[0].value.split("-")[2]), value: i };
        })
        .reduce((map, obj) => {
            map[obj.key] = obj.value;
            return map;
        }, {});
    // 0 date, 1 score
    const returnObj = { labels: [], data: [] };
    for (let i = 1; i <= daysInMonth; i++) {
        if (dayMap[i] !== undefined) {
            const idx = dayMap[i];
            const score = shiftList[idx][1].value;
            const tmpDate = shiftList[idx][0].value;
            returnObj.data.push(score);
            returnObj.labels.push(
                tmpDate.split("-")[1] + "/" + tmpDate.split("-")[2]
            );
        } else {
            returnObj.data.push(0);
            returnObj.labels.push(
                shiftList[0][0].value.split("-")[1] + "/" + i
            );
        }
    }
    return returnObj;
};

// this is for formatting percentages that come from the mtdPercentages response. used in MonthToDate
export const formatShiftPercentages = (percentages) => {
    if (
        percentages === undefined ||
        percentages === null ||
        percentages.length === 0
    )
        return [];

    return {
        labels: percentages.map(
            (elem) => `${elem[0].split("-")[1]}/${elem[0].split("-")[2]}`
        ),
        data: percentages.map((elem) => parseInt(elem[1] * 100)),
    };
};
