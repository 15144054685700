import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Card,
    CardContent,
    Grid,
    Tooltip,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    IconButton,
    Checkbox,
    ListItemText,
} from "@mui/material";
// Material UI Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import TocIcon from "@mui/icons-material/Toc";
import * as dateUtils from "../../../util/dateUtils/dateUtils";

const useStyles = makeStyles((theme) => ({
    firstItem: {
        flexGrow: 1,
    },
    lastItem: {
        flexShrink: 1,
    },
    item: {
        justifyContent: "center",
        flexGrow: 1,
    },
    subItem: {
        padding: 2,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    innerContainer: {
        marginTop: 10,
        marginBottom: 10,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: "#F50057",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#c51162",
        },
    },
    roleSelect: {
        width: "15%",
        maxWidth: 450,
        marginRight: 20,
    },
    divider: {
        width: "110%",
        padding: 0,
        margin: 0,
        marginLeft: -20,
    },
    cardContent: {
        "&:last-child": {
            paddingBottom: 16,
        },
    },
}));

const MissingShiftPageControl = (props) => {
    const classes = useStyles();
    const { startDate, endDate, custom } = props.filters;
    const { onChangeBack, onChangeForward, onChangeCustomDate } = props;
    const { onChangeDays, selectedDays } = props;

    return (
        <Card>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.innerContainer}>
                    <Grid
                        item
                        className={[classes.item, classes.firstItem].join(" ")}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<TocIcon />}
                            onClick={props.toggleGroupBy}
                        >
                            Group By{" "}
                            {props.isDayMissingShifts ? "User" : "Date"}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        className={[classes.item, classes.roleSelect].join(" ")}
                    >
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="day-selector">
                                Day of Week
                            </InputLabel>
                            <Select
                                labelId="day-selector-label"
                                id="day-selector-checkbox"
                                multiple
                                value={selectedDays}
                                onChange={(e) => onChangeDays(e)}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                }}
                            >
                                {dateUtils.daysOfWeek.map((day) => (
                                    <MenuItem key={day} value={day}>
                                        <Checkbox
                                            checked={
                                                selectedDays.indexOf(day) > -1
                                            }
                                        />
                                        <ListItemText
                                            primary={day}
                                            value={day}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        className={[classes.item, classes.roleSelect].join(" ")}
                    >
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Choose Role</InputLabel>
                            <Select
                                onChange={(e) => props.onChangeRole(e)}
                                value={props.selectedRole || ""}
                                renderValue={(val) => val.name}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                }}
                                disabled={props.isLoading}
                            >
                                {props.roles === undefined ||
                                props.roles === null
                                    ? []
                                    : props.roles?.map((role, i) => (
                                          <MenuItem key={"B" + i} value={role}>
                                              {role.name}
                                          </MenuItem>
                                      ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Divider variant="middle" className={classes.divider} />
                </Grid>
                <Grid container className={classes.innerContainer}>
                    <Grid item className={classes.firstItem}>
                        <Tooltip
                            title={"Go back a week"}
                            aria-label={"Go back a week"}
                        >
                            <IconButton
                                disabled={custom}
                                onClick={onChangeBack}
                                size="large"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item className={classes.item}>
                        <TextField
                            variant="standard"
                            className={classes.subItem}
                            disabled
                            key={"A12"}
                            required
                            type="date"
                            name={"startDate"}
                            label="Start Date"
                            onChange={(e) => onChangeCustomDate(e, "startDate")}
                            value={
                                startDate ||
                                new Date().toISOString().split("T")[0]
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            variant="standard"
                            className={classes.subItem}
                            disabled
                            key="SR71"
                            required
                            type="date"
                            name={"endDate"}
                            label="End Date"
                            onChange={(e) => onChangeCustomDate(e, "endDate")}
                            value={
                                endDate ||
                                new Date().toISOString().split("T")[0]
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item className={classes.lastItem}>
                        <Tooltip
                            title={"Go forward a week"}
                            aria-label={"Go forward a week"}
                        >
                            <IconButton
                                disabled={custom}
                                onClick={onChangeForward}
                                size="large"
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MissingShiftPageControl;
