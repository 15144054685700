import axios from "axios";

const retryWrapper = (axios, options) => {
    const max_time = options.retry_time;
    const retry_status_code = options.retry_status_code;
    let counter = 0;
    axios.interceptors.response.use(null, (error) => {
        /** @type {import("axios").AxiosRequestConfig} */
        const config = error.config;
        if (counter < max_time && error.response.status === retry_status_code) {
            counter++;
            return new Promise((resolve) => {
                resolve(axios(config));
            });
        }

        return Promise.reject(error);
    });
};

const api = axios.create({
    withCredentials: true,
});

retryWrapper(api, { retry_time: 3, retry_status_code: 502 });

export default api;
