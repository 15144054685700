import * as types from "./actionTypes";
// import { TOKEN } from './actionTypes';

export default function userReducer(
  state = {
    token: '',
  },
  action
) {
  switch (action.type) {
    case types.TOKEN:
      return {
        ...state,
        token: action.token
      };
    default:
      return state;
  }
}

