import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Zoom,
    Typography,
    Modal,
    Grid,
    Button,
    Backdrop,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// custom
import * as rtfUtil from "../../../util/textFieldRendering";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "85%",
        overflowY: "auto",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    buttonSuccess: {
        backgroundColor: "#56f500",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E0E0E0",
        },
    },
    buttonCancel: {
        backgroundColor: "#cfcfcf",
        "&:hover": {
            cursor: "pointer",
            color: "#E0E0E0",
            backgroundColor: "#c51162",
        },
    },
}));

const ShiftCreateModal = (props) => {
    const classes = useStyles();
    const renderTextField = rtfUtil.renderTextField;
    const [shift, setShift] = useState([]);
    const [prod, setProd] = useState([]);
    const [offProd, setOffProd] = useState([]);
    const [userId, setUserId] = useState(null);

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // This precludes the use of '.' (i.e. no floats)
        if (
            (!Object.is(NaN, parseInt(value)) &&
                value.toString().includes(".")) ||
            value[0] === "."
        )
            return;

        if (name === "user") {
            setUserId(value);
            return;
        }
        setShift((prevShift) => {
            for (let elem of prevShift) {
                if (elem.name === name) {
                    elem.value = value;
                    break;
                }
            }
            return [...prevShift];
        });
        const offProd = shift.filter((elem) => elem.name.includes("OffProd"));
        const prod = shift.filter((elem) => !elem.name.includes("OffProd"));
        setProd(prod);
        setOffProd(offProd);
    };

    // using state because rerender cycle was too slow to pass down props
    useEffect(() => {
        if (props.tasks !== undefined) {
            const newShift = [...props.tasks].map((elem) => {
                return { ...elem, value: rtfUtil.getShiftDefaults(elem.type) };
            });
            const offProd = newShift.filter((elem) =>
                elem.name.includes("OffProd")
            );
            const prod = newShift.filter(
                (elem) => !elem.name.includes("OffProd")
            );
            setShift(newShift);
            setOffProd(offProd);
            setProd(prod);
            setUserId(null);
        }
    }, [setShift, setOffProd, setProd, props.tasks, props.newShift]);

    const isDuplicateShift = (shifts) => {
        const createDate = prod.find((attr) => attr.name === "shiftDate").value;
        const curUser = props.users.find((user) => user.id === userId);
        for (var shift of props.userShifts) {
            if (
                shift.find((attr) => attr.name === "shiftDate").value ===
                    createDate &&
                curUser &&
                shift.find((attr) => attr.name === "name").value ===
                    `${curUser.fname} ${curUser.lname}`
            )
                return true;
        }
        return false;
    };

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={(e) => props.onSubmit(e, shift, userId)}>
                        <Typography variant="h6" gutterBottom>
                            Add Daily Production
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>User</InputLabel>
                                    <Select
                                        onChange={onChange}
                                        value={userId || ""}
                                        name="user"
                                        error={userId === null}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                        }}
                                    >
                                        {props.users
                                            .filter(
                                                (user) =>
                                                    user.fname !== "All" &&
                                                    user.lname !== "Users"
                                            )
                                            .map(
                                                ({
                                                    id,
                                                    fname,
                                                    lname,
                                                    email,
                                                }) => (
                                                    <MenuItem
                                                        key={"N" + id}
                                                        value={id}
                                                    >
                                                        {fname} {lname}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                    {userId !== null ? null : (
                                        <FormHelperText error>
                                            {" "}
                                            User Required{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {prod !== [] ? (
                                prod.map((shift, indx) => {
                                    return shift.name === "shiftDate" ||
                                        shift.name === "shiftStart" ||
                                        shift.name === "shiftEnd" ||
                                        shift.name === "lunchDuration" ? (
                                        <Grid item xs={6} key={"b" + indx}>
                                            {renderTextField(
                                                shift,
                                                indx,
                                                onChange,
                                                false,
                                                shift.name === "shiftDate" &&
                                                    isDuplicateShift(
                                                        props.shifts
                                                    )
                                            )}
                                        </Grid>
                                    ) : shift.name === "comments" ? (
                                        <Grid item xs={12} key={"b" + indx}>
                                            {renderTextField(
                                                shift,
                                                indx,
                                                onChange
                                            )}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={4} key={"b" + indx}>
                                            {renderTextField(
                                                shift,
                                                indx,
                                                onChange
                                            )}
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid item xs={12}>
                                    {" "}
                                    <Typography varient="h2">
                                        {" "}
                                        No Data{" "}
                                    </Typography>
                                </Grid>
                            )}
                            <Accordion className={classes.button}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel-header"
                                >
                                    <Typography varient="h2">
                                        {" "}
                                        Off Production Tasks
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={[
                                        classes.center,
                                        classes.button,
                                    ].join(" ")}
                                >
                                    <Grid container>
                                        {offProd !== [] ? (
                                            offProd.map((shift, indx) => (
                                                <Grid
                                                    item
                                                    xs={4}
                                                    key={"q" + indx}
                                                    className={
                                                        classes.paddedGrid
                                                    }
                                                >
                                                    {renderTextField(
                                                        shift,
                                                        indx,
                                                        onChange
                                                    )}
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                {" "}
                                                <Typography varient="h2">
                                                    {" "}
                                                    No Data{" "}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={[
                                        classes.button,
                                        classes.buttonSuccess,
                                    ].join(" ")}
                                    disabled={
                                        prod.find(
                                            (shift) =>
                                                shift.name === "shiftDate"
                                        ) !== undefined &&
                                        isDuplicateShift(props.shifts)
                                    }
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={[
                                        classes.button,
                                        classes.buttonCancel,
                                    ].join(" ")}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default ShiftCreateModal;
