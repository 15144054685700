import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import {
    Zoom,
    Typography,
    TextField,
    Select,
    Modal,
    MenuItem,
    InputLabel,
    Grid,
    FormControl,
    Button,
    Backdrop,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        height: 500,
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    buttonSuccess: {
        backgroundColor: "#56f500",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E0E0E0",
        },
    },
    buttonCancel: {
        backgroundColor: "#cfcfcf",
        "&:hover": {
            cursor: "pointer",
            color: "#E0E0E0",
            backgroundColor: "#c51162",
        },
    },
}));

const TaskUpdateModal = (props) => {
    const classes = useStyles();

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={props.onSubmit}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            variant="standard"
                        >
                            {props.title}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    placeholder="Name"
                                    onChange={props.onChange}
                                    value={props.task?.name || ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        name="type"
                                        onChange={props.onChange}
                                        value={props.task?.type || ""}
                                    >
                                        <MenuItem key="A1" value="number">
                                            Number
                                        </MenuItem>
                                        <MenuItem key="A2" value="text">
                                            Text
                                        </MenuItem>
                                        <MenuItem key="A3" value="select">
                                            Select
                                        </MenuItem>
                                        <MenuItem key="A4" value="date">
                                            Date
                                        </MenuItem>
                                        <MenuItem key="A5" value="time">
                                            Time
                                        </MenuItem>
                                        <MenuItem key="A6" value="datetime">
                                            Datetime
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name="label"
                                    label="Label"
                                    placeholder="Label"
                                    onChange={props.onChange}
                                    value={props.task?.label || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    name="standard"
                                    label="Standard"
                                    placeholder="Standard"
                                    onChange={props.onChange}
                                    value={props.task?.standard || ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    className={[
                                        classes.button,
                                        classes.buttonSuccess,
                                    ].join(" ")}
                                    variant="contained"
                                    type="submit"
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    className={[
                                        classes.button,
                                        classes.buttonCancel,
                                    ].join(" ")}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default TaskUpdateModal;
