import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
    Card,
    CardContent,
    Grid,
    Tooltip,
    Grow,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    IconButton,
} from "@mui/material";
// Material UI Icons
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    firstItem: {
        paddingLeft: 10,
    },
    item: {
        justifyContent: "center",
        flexGrow: 1,
    },
    container: {
        display: "flex",
        flexGrow: 1,
    },
}));

const RoleSelect = (props) => {
    const classes = useStyles();

    return (
        <Grow in={true} timeout={500}>
            <Card>
                <CardContent>
                    <Grid container className={classes.container}>
                        <Grid
                            item
                            className={[classes.item, classes.firstItem].join(
                                " "
                            )}
                        >
                            <Tooltip title="Add Role" aria-label="Add Role">
                                <IconButton
                                    disabled={false}
                                    onClick={props.onAdd}
                                    size="large"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete Role"
                                aria-label="Delete Role"
                            >
                                <IconButton
                                    disabled={false}
                                    onClick={(e) => props.onDelete(e)}
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item className={classes.item}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel>Choose Role</InputLabel>
                                <Select
                                    onChange={(e) => props.onChangeRole(e)}
                                    value={props.selectedRole || ""}
                                    renderValue={(val) => val.name}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                        },
                                    }}
                                >
                                    {props.roles === undefined ||
                                    props.roles === null
                                        ? []
                                        : props.roles?.map((role, i) => (
                                              <MenuItem
                                                  key={"B" + i}
                                                  value={role}
                                              >
                                                  {role.name}
                                              </MenuItem>
                                          ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grow>
    );
};

export default RoleSelect;
