import * as types from './actionTypes';

export const setUser = user => {
  return {
    type: types.USER,
    user
  };
};

const setUserSuccess = userSuccess => {
  return {
    type: types.USER_SUCCESS,
    userSuccess
  };
};

const setUserError = userError => {
  return {
    type: types.USER_ERROR,
    userError
  };
};

export const getUser = (user) => {
  return async dispatch => {
    try {
      dispatch(setUser(user));
      dispatch(setUserSuccess(true));
    } catch (err) {
      dispatch(setUserError(err))
    };
  };
};