import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import { Container, Grow, Snackbar, Box } from "@mui/material";
// Material UI Icons
import { AddBox, Edit } from "@mui/icons-material/";
import MuiAlert from "@mui/material/Alert";
// Custom
import { tableIcons } from "../../util/tableIcons";
import api from "../../api";
import { SHIFTS, FORMATTED_SHIFTS } from "../../api/routes";
import ShiftUpdateModal from "../Modals/ShiftMaintenanceModals/ShiftUpdateModal";
import ShiftCreateModal from "../Modals/ShiftMaintenanceModals/ShiftCreateModal";
import * as rtfUtil from "../../util/textFieldRendering";
import { getSites, setCurrentSite } from "../../store/sites/sitesActions";
import { setRoles } from "../../store/roles/rolesActions";
import { setTasks } from "../../store/tasks/tasksActions";
import { setPages } from "../../store/pages/pagesActions";
import * as models from "../../util/models";
// Custom JSX components
import UserDashboard from "./UserDashboard";
import ShiftRangeToggle from "./ShiftRangeToggle";
import * as dateUtils from "../../util/dateUtils/dateUtils";
import * as mtExport from "../../util/materialTableExport";

export const roleTasksTime = [
    { name: "shiftDate", type: "date", label: "Shift Date" },
    { name: "shiftStart", type: "time", label: "Shift Start" },
    { name: "shiftEnd", type: "time", label: "Shift End" },
    { name: "lunchDuration", type: "number", label: "Lunch Duration" },
];

export const roleTasksOffProd = [
    { name: "comments", type: "textarea", label: "Comments" },
    { name: "breakOffProd", type: "number", label: "Break" },
    { name: "systemDownOffProd", type: "number", label: "System Down" },
    { name: "meetingOffProd", type: "number", label: "Meeting" },
    { name: "trainingOffProd", type: "number", label: "Training" },
    { name: "projectOffProd", type: "number", label: "Project" },
    { name: "miscOffProd", type: "number", label: "Miscellaneous" },
];

const DailyProduction = () => {
    const dispatch = useDispatch();
    const { currentSite, token, tasks } = useSelector((state) => ({
        currentSite: state.sitesReducer.currentSite,
        token: state.authReducer.token,
        tasks: state.tasksReducer.tasks,
    }));

    const [state, setState] = useState({
        columns: [],
        isLoading: false,
        isArchived: false,
        error: false,
        shiftAdded: false,
        shiftUpdated: false,
        shiftCreateError: false,
        shiftsArchivedError: false,
        shiftUpdateError: false,
        shiftUpdateModalOpen: false,
        shiftCreateModalOpen: false,
        showRowScores: false,
        newShift: [],
        shifts: [],
        originalShifts: [],
        shiftScores: [],
        tasks: [],
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
        shiftPercentages: [0, 0],
        isInitialized: false,
    });

    const getUserPercentages = useCallback(
        async (startDate, endDate, curSite) => {
            let percentages = await await api.get(
                `${FORMATTED_SHIFTS}/user/percentages/${curSite.id}`,
                {
                    headers: { Authorization: token },
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                    },
                }
            );
            setState((prevState) => ({
                ...prevState,
                shiftPercentages: percentages.data,
            }));
        },
        [token]
    );

    const getShifts = async (startDate, endDate) => {
        let shifts = await api.get(`${SHIFTS}/user/${currentSite.id}`, {
            headers: { Authorization: token },
            params: {
                startDate: state.filters.startDate,
                endDate: state.filters.endDate,
            },
        });
        // format score as percentage
        shifts = shifts.data.map((shift) =>
            shift.map((elem) => {
                if (elem.name === "score")
                    elem.value = Math.round(elem.value * 100);

                return elem;
            })
        );
        const bool = isArchived(shifts);

        let shiftScores = updateScores(shifts);
        setState((prevState) => ({
            ...prevState,
            shifts,
            originalShifts: shifts,
            shiftScores,
            isLoading: false,
            isArchived: bool,
        }));
    };

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        // needed this hack to pass current state to getShifts, it was receiving previous state.
        const fetchAPI = async () => {
            if (!state.isInitialized) {
                const fetch = currentSite ? 
                    await models.getFetchSite(token, currentSite.id) :
                    await models.getFetch(token);

                dispatch(getSites(fetch.data.sites));
                if (currentSite === undefined)
                    dispatch(setCurrentSite(fetch.data.currentSite));
                dispatch(setRoles(fetch.data.roles));
                dispatch(setPages(fetch.data.pages));
                const concatTasks = roleTasksTime
                    .concat(fetch.data.tasks)
                    .concat(roleTasksOffProd);
                dispatch(setTasks(concatTasks));
                setState((prevState) => ({
                    ...prevState,
                    userRole: fetch.data.userRole,
                    isInitialized: true,
                }));
            } else {
                await getShifts(state.filters.startDate, state.filters.endDate);
                await getUserPercentages(
                    state.filters.startDate,
                    state.filters.endDate,
                    currentSite
                );
            }
        };
        fetchAPI();

        const columns = [
            { title: "Shift Date", field: "shiftDate" },
            { title: "Earned Hours", field: "hoursEarned" },
            { title: "Hours Worked", field: "hoursWorked" },
            { title: "Total Off Production", field: "offProd" },
        ];
        setState((prevState) => ({
            ...prevState,
            tasks,
            columns,
            isLoading: false,
        }));
    }, [
        state.filters.startDate,
        state.filters.endDate,
        state.isInitialized,
        state.serverUpdate,
    ]);

    /**
     * ===== SHIFT TRANSFORMATION FUNCTIONS =====
     */
    const updateScores = (shifts) => {
        // make deep copy of shifts
        let shiftScores = shifts.map((shft) => {
            return shft.map((obj) => {
                return { ...obj };
            });
        });
        // create a map of name -> standard for custom tasks and OffProd tasks.
        const taskValueMap = {};
        for (let obj of tasks) {
            if (obj.standard !== undefined) {
                taskValueMap[obj.name] = +obj.standard;
            } else if (obj.name.includes("OffProd")) {
                taskValueMap[obj.name] = 60.0;
            }
        }
        // find score percentage for every task in the map
        for (let shift of shiftScores) {
            for (let shiftTask of shift) {
                if (taskValueMap[shiftTask.name] !== undefined) {
                    const tmp = shiftTask.value / taskValueMap[shiftTask.name]; //* 100
                    shiftTask.value = tmp.toFixed(1);
                }
            }
        }
        return shiftScores;
    };

    const formatShifts = () => {
        const tableShifts = [];
        for (let shift of state.shiftScores) {
            const shiftDate = shift.find((elem) => elem.name === "shiftDate");
            const shiftStart = shift.find((elem) => elem.name === "shiftStart");
            const shiftEnd = shift.find((elem) => elem.name === "shiftEnd");
            const lunchDuration = shift.find(
                (elem) => elem.name === "lunchDuration"
            );
            let dateString = `${shiftDate.value} ${shiftStart.value}`;
            let startTime = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
            dateString = `${shiftDate.value} ${shiftEnd.value}`;
            let endTime = Date.parse(dateString, "yyyy-MM-dd HH:mm:ss");
            const hoursWorked =
                (Math.abs(startTime - endTime) / 60000 - lunchDuration.value) /
                60;

            // filter to only offProd attributes, map to convert value from hourly time to minutes. sum
            const offProdHours = shift
                .filter((elem) => elem.name.includes("OffProd"))
                .reduce((a, b) => a + +b.value, 0);
            const prodHours = shift
                .filter(
                    (elem) =>
                        elem.name !== "id" &&
                        elem.name !== "shiftDate" &&
                        elem.name !== "shiftStart" &&
                        elem.name !== "shiftEnd" &&
                        elem.name !== "lunchDuration" &&
                        elem.name !== "shiftDuration" &&
                        elem.name !== "userRoleId" &&
                        elem.name !== "comments" &&
                        elem.name !== "isApproved" &&
                        elem.name !== "score" &&
                        !elem.name.includes("OffProd")
                )
                .reduce((a, b) => a + +b.value, 0);

            const hoursEarned = prodHours + offProdHours;

            const tmp = [
                {
                    name: "shiftDate",
                    type: "date",
                    label: "Shift Date",
                    value: shiftDate.value,
                },
                {
                    name: "score",
                    type: "text",
                    label: "Score",
                    value: shift.find((elem) => elem.name === "score").value,
                },
                {
                    name: "hoursEarned",
                    type: "number",
                    label: "Earned Hours",
                    value: hoursEarned.toFixed(1),
                },
                {
                    name: "hoursWorked",
                    type: "number",
                    label: "Hours Worked",
                    value: hoursWorked.toFixed(1),
                },
                {
                    name: "offProd",
                    type: "number",
                    label: "Total Off Production",
                    value: offProdHours.toFixed(1),
                },
            ];
            tableShifts.push(tmp);
        }
        return tableShifts;
    };

    const deleteShift = async (shiftTableRow) => {
        try {
            // find the ID associated with the shift's date. assuming one shift per day each user
            // shift table uses computed fields heavily so ID had to be removed...
            const idObjArr = state.shifts
                .map((shift) => {
                    const foundDate = shift.find(
                        (attr) =>
                            attr.name === "shiftDate" &&
                            attr.value === shiftTableRow.shiftDate
                    );
                    const foundId = shift.find((attr) => attr.name === "id");
                    return foundDate !== null && foundDate !== undefined
                        ? foundId
                        : null;
                })
                .filter((obj) => obj !== null);
            await api.delete(`${SHIFTS}/${idObjArr[0].value}`, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                shifts: [...prevState.shifts.filter(
                    (shift) => {
                        console.log(shift)
                        return shift.find(
                            (attr) =>
                                attr.name === "shiftDate" &&
                                attr.value === shiftTableRow.shiftDate
                        ) !== shiftTableRow.shiftDate
                    })],
                shiftDeleted: true,
            }));
            const fetchAPI = async () =>
                await getShifts(state.filters.startDate, state.filters.endDate);
            fetchAPI();
        } catch (err) {
            setState((prevState) => ({
                ...prevState,
                shiftDeleteError: true,
            }));
        }
    };

    /*
     * ===== SHIFT FILTER FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    /*
     * ===== GENERAL MODAL FUNCTIONS =====
     */
    const renderShiftUpdateModal = (rowData) => {
        const shiftDate = rowData.shiftDate;
        let selectedShiftForEdit;
        for (let i = 0; i < state.shifts.length; i++) {
            if (
                shiftDate ===
                state.shifts[i].find((attr) => attr.name === "shiftDate").value
            ) {
                selectedShiftForEdit = [...state.shifts[i]].filter(
                    (obj) => obj.name !== "score" && obj.name !== "userRoleId"
                );
                break;
            }
        }
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: true,
            selectedShiftForEdit: selectedShiftForEdit,
        }));
    };

    const handleUpdateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdateModalOpen: false,
            selectedShiftForEdit: [],
            shifts: prevState.originalShifts,
        }));
    };

    const renderShiftCreateModal = () => {
        if (state.newShift.length > 0) {
            let tmpRole = [...tasks];
            const newShift = tmpRole.map((elem) => {
                return { ...elem, value: rtfUtil.getShiftDefaults(elem.type) };
            });

            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
                newShift,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                shiftCreateModalOpen: true,
            }));
        }
    };

    const handleCreateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            shiftCreateModalOpen: false,
        }));
    };

    /*
     * ===== UPDATE SHIFT MODAL FUNCTIONS =====
     */
    const onShiftUpdateSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftUpdateModalOpen: false,
        }));

        try {
            // add user_role_id
            const withUserRole = [...state.selectedShiftForEdit];
            withUserRole.push({
                name: "userRoleId",
                label: "User Role Id",
                type: "number",
                value: state.userRole.id,
            });
            await api.put(`${SHIFTS}/${currentSite.id}`, withUserRole, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdated: true,
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch (err) {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftUpdateError: true,
            }));
        }
    };

    const onShiftUpdateChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        // This precludes the use of '.' (i.e. no floats)
        if (
            (!Object.is(NaN, parseInt(value)) && value.includes(".")) ||
            value[0] === "."
        )
            return;

        const prevShift = [...state.selectedShiftForEdit].map((elem) => {
            return { ...elem };
        });
        for (let elem of prevShift) {
            if (elem.name === name) {
                elem.value = value;
                break;
            }
        }

        setState((prevState) => ({
            ...prevState,
            selectedShiftForEdit: prevShift,
        }));
    };

    const isArchived = (shifts) => {
        if (shifts && shifts.length <= 0) return false;
        else
            return (
                shifts[0].find((attr) => attr.name === "isApproved").value ===
                "true"
            );
    };

    /*
     * ===== CREATE SHIFT MODAL FUNCTIONS =====
     */
    const onShiftCreateChange = (e) => {
        setState((prevState) => {
            const name = e.target.name;
            const value = e.target.value;

            const newShift = [...prevState.newShift];
            for (let elem of newShift) {
                if (elem.name === name) {
                    elem.value = value;
                    break;
                }
            }
            return { ...prevState, newShift };
        });
    };

    // Snackbar Function
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftsArchivedError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const onShiftCreateSubmit = async (e, newShift) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            shiftCreateModalOpen: false,
        }));
            // add a dummy id so the server can process the shift.
        const shiftWithId = [
            { name: "id", type: "number", label: "ID", value: 1 },
            {
                name: "isApproved",
                type: "boolean",
                label: "Is Approved",
                value: false,
            },
            {
                name: "userRoleId",
                type: "number",
                label: "User Role Id",
                value: state.userRole.id,
            },
        ].concat(newShift);
        await api.post(`${SHIFTS}/${currentSite.id}`, shiftWithId, {
            headers: { Authorization: token },
        }).then((resp) => {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                shiftAdded: true,
                newShift: [],
                serverUpdate: !prevState.serverUpdate,
            }));
        }).catch((resp) => {
        const shiftErrorKey = resp.message.includes("409") ? "shiftsArchivedError" : "shiftCreateError";
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            [shiftErrorKey]: true,
        }));
    })
    };

    const tableShifts = formatShifts();
    const columns = [
        { title: "Shift Date", field: "shiftDate" },
        { title: "Score", field: "score" },
        { title: "Earned Hours", field: "hoursEarned" },
        { title: "Hours Worked", field: "hoursWorked" },
        { title: "Total Off Production", field: "offProd" },
    ];

    const exportArray = 
        mtExport.exportCSVShift(
            tableShifts.length <= 0, 
            tableShifts, 
            "daily-production-" +
            state.filters.startDate.split("-")[1] +
            "-" +
            state.filters.startDate.split("-")[0]
        );

    return (
        <>
            <Container>
                <Box mt={1} mb={1}>
                    <ShiftRangeToggle
                        filters={state.filters}
                        onChangeBack={onChangeBack}
                        onChangeForward={onChangeForward}
                    />
                </Box>
                <Box mb={1}>
                    <UserDashboard
                        shifts={tableShifts}
                        shiftPercentages={state.shiftPercentages}
                    />
                </Box>
                {state.isArchived ? (
                    <Box mt={1} mb={1}>
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            severity="info"
                        >
                            The Shifts for{" "}
                            {state.selectedRole && state.selectedRole.name
                                ? state.selectedRole.name
                                : ""}{" "}
                            have been archived for this month.
                        </MuiAlert>
                    </Box>
                ) : null}
                <Grow in={true} timeout={500}>
                    {tasks === [] ? (
                        <div> Loading...</div>
                    ) : (
                        <Box mb={1}>
                            <MaterialTable
                                title={`Daily Production Tracking`}
                                columns={columns}
                                data={tableShifts.map((arr) => {
                                    let obj = {};
                                    arr.forEach(
                                        (col) =>
                                            (obj[col.name] =
                                                rtfUtil.tableRender(col))
                                    );
                                    return obj;
                                })}
                                isLoading={state.isLoading}
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    headerStyle: {
                                        backgroundColor: "#eee",
                                        fontWeight: "bold",
                                    },
                                    exportMenu: exportArray
                                }}
                                actions={[
                                    {
                                        disabled: state.isArchived,
                                        icon: forwardRef((props, ref) => (
                                            <Edit {...props} ref={ref} />
                                        )),
                                        tooltip: "Edit Daily Production",
                                        onClick: (e, rowData) => {
                                            renderShiftUpdateModal(rowData);
                                        },
                                    },
                                    {
                                        disabled: state.isArchived,
                                        icon: forwardRef((props, ref) => (
                                            <AddBox {...props} ref={ref} />
                                        )),
                                        tooltip: "Add Daily Production",
                                        onClick: (e) =>
                                            renderShiftCreateModal(),
                                        isFreeAction: true,
                                    },
                                ]}
                                editable={{
                                    isDeletable: (shiftToDelete) =>
                                        !state.isArchived,
                                    onRowDelete: (shiftToDelete) =>
                                        deleteShift(shiftToDelete),
                                }}
                            />
                        </Box>
                    )}
                </Grow>
            </Container>
            <ShiftUpdateModal
                open={state.shiftUpdateModalOpen}
                onClose={handleUpdateModalClose}
                onChange={onShiftUpdateChange}
                shift={state.selectedShiftForEdit}
                onSubmit={onShiftUpdateSubmit}
            />
            <ShiftCreateModal
                open={state.shiftCreateModalOpen}
                onClose={handleCreateModalClose}
                onChange={onShiftCreateChange}
                newShift={state.newShift}
                shifts={state.shifts}
                roleTasks={state.columns}
                tasks={tasks}
                onSubmit={onShiftCreateSubmit}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.shiftUpdated ||
                    state.shiftAdded ||
                    state.shiftDeleted ||
                    state.shiftUpdateError ||
                    state.shiftCreateError ||
                    state.shiftsArchivedError ||
                    state.shiftDeleteError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
                classes={{ root: { zIndex: 2500 } }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    open={
                        state.shiftUpdated        ||
                        state.shiftAdded          ||
                        state.shiftDeleted        ||
                        state.shiftUpdateError    ||
                        state.shiftCreateError    ||
                        state.shiftsArchivedError ||
                        state.shiftDeleteError
                    }
                    severity={
                        state.shiftUpdated ||
                        state.shiftAdded   ||
                        state.shiftDeleted
                            ? "success"
                            : state.shiftUpdateError    ||
                              state.shiftCreateError    ||
                              state.shiftsArchivedError ||
                              state.shiftDeleteError
                            ? "error"
                            : undefined
                    }
                    style={{ zIndex: 2500 }}
                    classes={{ root: { zIndex: 2500 } }}
                >
                    {state.shiftUpdated
                        ? "Daily Production Updated"
                        : state.shiftAdded
                        ? "Daily Production Added"
                        : state.shiftDeleted
                        ? "Daily Production Deleted"
                        : state.shiftCreateError
                        ? "Error Creating Daily Production"
                        : state.shiftsArchivedError
                        ? "Cannot create shift for archived Month"
                        : state.shiftUpdateError
                        ? "Error Updating Daily Production"
                        : state.shiftDeleteError
                        ? "Error Deleting Daily Production"
                        : null}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default DailyProduction;
