import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Grid, Snackbar } from "@mui/material";
// Material UI Icons
import { AddBox, Edit } from "@mui/icons-material/";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MuiAlert from "@mui/material/Alert";
// Custom
import { tableIcons } from "../../../util/tableIcons";
import api from "../../../api";
import { TASKS, PAGES, ROLES, SHIFTS } from "../../../api/routes";
import RoleSelect from "./RoleSelect";
import TaskUpdateModal from "../../Modals/RoleMaintenanceModals/TaskUpdateModal";
import RoleCreateModal from "../../Modals/RoleMaintenanceModals/RoleCreateModal";
import RoleDeleteModal from "../../Modals/RoleMaintenanceModals/RoleDeleteModal";
import TaskDeleteModal from "../../Modals/RoleMaintenanceModals/TaskDeleteModal";
import ArchiveProbeModal from "../../Modals/RoleMaintenanceModals/ArchiveProbeModal";
import PageList from "./PageList";
import * as dtUtils from "../../../util/dateUtils/dateUtils";
import * as models from "../../../util/models";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
    grid: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    topPad: {
        paddingBottom: 10,
        margin: 5,
    },
}));

/**
 * 
 * The process of changing tasks starts with the event triggered in the MaterialTable. 
 * This stimulates an invocation of the useEffect passing it 2 important pieces of info via state: 
 * rowData to be used in update and the updateType {create, update, delete}, this is done 
 * by flipping the serverUpdate flag to trigger a rerun of useEffect.  
 * 
 * The use effect will hit a new if case where rowData is defined. This if calls the probe task update endpoint then
 * it will call the respective create, update, delete modal render function.
 * 
 * In the render functionn, if all shifts are archived the respective create, update, and delete modals render as before. 
 * If all shifts are not archived then an intermediate modal renders, showing the user the shifts to be affected. 
 * If the user submits this modal, the submission handler uses the updateType to render the appropriate modal:
 *  {create, update, or delete modal}
 * 
 */
const RoleMaintenance = () => {
    const classes = useStyles();

    const { token, currentSite } = useSelector(
        (state) => ({
            token: state.authReducer.token,
            currentSite: state.sitesReducer.currentSite,
        }),
        shallowEqual
    );

    const columns = [
        { title: "Name", field: "name" },
        { title: "Type", field: "type" },
        { title: "Label", field: "label" },
        { title: "Standard", field: "standard" },
    ];

    const [state, setState] = useState({
        isLoading: true,
        isArchived: false,
        unarchivedDateString: "",
        roleAdded: false,
        taskUpdated: false,
        taskCreated: false,
        roleDeleted: false,
        taskUpdateModalOpen: false,
        taskCreateModalOpen: false,
        taskDeleteModalOpen: false,
        roleCreateModalOpen: false,
        roleDeleteModalOpen: false,
        probeTaskChangeOpen: false,
        newTask: {},
        newRole: {},
        tasks: [],
        allPages: [],
        selectedPages: [],
        shifts: [],
        roles: [],
        selectedRole: null,
        serverUpdate: false,
    });

    const getTasks = useCallback(
        async (roleId) => {
            let tasks = await api.get(`${TASKS}/${roleId}`, {
                headers: { Authorization: token },
            });
            return tasks.data;
        },
        [token]
    );

    const getAllPages = useCallback(async () => {
        let pages = await api.get(`${PAGES}`, {
            headers: { Authorization: token },
        });
        setState((prevState) => ({
            ...prevState,
            allPages: pages.data,
            isLoading: false,
        }));
    }, [token]);

    const getSelectedPages = useCallback(
        async (roleId) => {
            const response = await api.get(`${PAGES}/${roleId}`, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                selectedPages: response.data,
            }));
        },
        [token]
    );

    const getRoles = useCallback(
        async (siteId) => {
            const roles = await api.get(`${ROLES}/site/${siteId}`, {
                headers: { Authorization: token },
            });
            setState((prevState) => ({
                ...prevState,
                roles: roles.data,
            }));
        },
        [token]
    );

    const isArchived = (shifts) => {
        if (shifts?.length <= 0) {
            return { bool: true, unarchivedDateString: "" };
        }
        else {
            let notArchivedBool;
            let isArchivedBool = true;
            let unarchivedMap = {};
            let isArchivedBoolList = [];
            for (let shift of shifts) {
                notArchivedBool = shift.find((attr) => attr.name === "isApproved").value !== "true";
                isArchivedBoolList.push(!notArchivedBool)
                if (notArchivedBool) {
                    isArchivedBool = false;
                    const dt = shift.find(
                        (attr) => attr.name === "shiftDate"
                    ).value;
                    const month = dtUtils.intToMonth[+dt.split("-")[1] - 1];
                    if (unarchivedMap[month] === undefined)
                        unarchivedMap[month] = 1;
                    else unarchivedMap[month] += 1;
                }
            }
            return {
                bool: isArchivedBoolList.reduce((b1, b2) => b1 && b2),
                unarchivedDateString: Object.entries(unarchivedMap).reduce(
                    (str, [key, val]) => `${str}, ${key}:${val}`,
                    ""
                ),
            };
        }
    };

    const getShifts = useCallback(async (roleId) => {
        let shifts = await api.get(`${SHIFTS}/role/${roleId}`, {
            headers: { Authorization: token },
        });
        return shifts.data;
    });

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        getRoles(currentSite.id);
        if(state.allPages.length === 0) getAllPages();
        if(state.selectedRole !== null) {
            Promise.all([
                getTasks(state.selectedRole.id),
                getShifts(state.selectedRole.id)
            ]).then((values) => {
                setState((prevState) => ({
                    ...prevState,
                    tasks: values[0],
                    shifts: values[1],
                }));
            })
            setState((prevState) => ({
                ...prevState, 
                isLoading: prevState.rowData !== undefined, // allows next if block to load
            }))
        }
        if(state.rowData !== undefined) {
            const isArchivedObj = isArchived(state.shifts)
            // if this is create get a different task
            const taskId = state.rowData.id       ? 
                           state.rowData.id       :
                           state.tasks.length > 0 ?
                           state.tasks[0].id      :
                           null;

            const affectedShiftPromise = taskId == null ? 
                                Promise.resolve({data: []}) :
                                   models.getShiftArchiveProbe(token, state.selectedRole.id, taskId);

            affectedShiftPromise.then((affectedShiftsResp) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }));
                switch(state.updateType) {
                    case "create": 
                    renderMenuCreateModal({ ...state.rowData }, isArchivedObj, affectedShiftsResp.data);
                    break;
                    case "update": 
                        renderMenuUpdateModal({ ...state.rowData }, isArchivedObj, affectedShiftsResp.data);
                        break;
                    case "delete":
                        onTaskDeleteOpen({ ...state.rowData }, isArchivedObj, affectedShiftsResp.data);
                        break;
                    default:
                        break;
                }
            })
        }
    }, [state.selectedRole, state.serverUpdate, getTasks, getAllPages]);

    // Snackbar Function
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            roleDeleted: false,
            roleCreated: false,
            taskUpdated: false,
            taskCreated: false,
            taskDeleted: false,
            pageAccessUpdated: false,
            roleDeleteError: false,
            roleCreateError: false,
            taskUpdateError: false,
            taskCreateError: false,
            taskDeleteError: false,
            pageUpdateError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            roleDeleted: false,
            roleCreated: false,
            taskUpdated: false,
            taskCreated: false,
            taskDeleted: false,
            pageAccessUpdated: false,
            roleDeleteError: false,
            roleCreateError: false,
            taskUpdateError: false,
            taskCreateError: false,
            taskDeleteError: false,
            pageUpdateError: false,
        }));
    };

    /**
     * ===== PAGELIST FUNCTIONS =====
     */
    const onSubmitPageList = async (e, updatedPages) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        try {
            // (roleId, pageId)
            const revokeAccess = [...state.allPages]
                .filter(
                    (page) =>
                        updatedPages.find((up) => up.id === page.id) ===
                        undefined
                )
                .map((obj) => [state.selectedRole.id, obj.id]);

            await api.post(`${PAGES}/${state.selectedRole.id}`, updatedPages, {
                headers: { Authorization: token },
            });
            await api.delete(PAGES, {
                headers: { Authorization: token },
                data: revokeAccess,
            });
            getSelectedPages(state.selectedRole.id);

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                pageAccessUpdated: true,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                pageUpdateError: true,
            }));
        }
    };

    /**
     * ===== MODAL FUNCTIONS =====
     */
    const renderMenuCreateModal = (rowData, isArchivedObj, affectedShifts) => {
        if (isArchivedObj.bool === true) {
            setState((prevState) => ({
                ...prevState,
                rowData,
                taskCreateModalOpen: true,
            }));  
        } else {
            setState((prevState) => ({
                ...prevState,
                probeTaskChangeOpen: true,
                affectedShifts,
            }))
        }
    };

    const renderMenuUpdateModal = (rowData, isArchivedObj, affectedShifts) => {
        if (isArchivedObj.bool === true) {
            setState((prevState) => ({
                ...prevState,
                taskUpdateModalOpen: true,
                selectedTaskForEdit: rowData,
                rowData: undefined,
            }));    
        } else {
            setState((prevState) => ({
                ...prevState,
                probeTaskChangeOpen: true,
                affectedShifts,
            }))
        }
    };

    const handleTaskUpdateModalClose = () => 
        setState((prevState) => ({
            ...prevState,
            taskUpdateModalOpen: false,
            selectedTaskForEdit: {},
            rowData: undefined,
            updateType: undefined,
        }));

    const handleTaskCreateModalClose = () => 
        setState((prevState) => ({
            ...prevState,
            taskCreateModalOpen: false,
            selectedTaskForEdit: {},
            newTask: {},
            rowData: undefined,
            updateType: undefined,
        }));

    const handleTaskDeleteModalClose = () =>  
        setState((prevState) => ({
            ...prevState,
            taskDeleteModalOpen: false,
            taskToDelete: undefined,
            rowData: undefined,
            updateType: undefined,
        }));


    /*
     * ===== UPDATE MENU MODAL FUNCTIONS =====
     */

    const onTaskDeleteSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            updateType: undefined,
            rowData: undefined,
        }));
        try {
            const taskToDelete = { ...state.taskToDelete };
            delete taskToDelete.tableData;
            taskToDelete.standard = taskToDelete.standard.toString();
            await api.delete(TASKS, {
                headers: { Authorization: token },
                data: taskToDelete,
            });
            getTasks(state.selectedRole.id);
            // remove deleted task from ui
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                taskToDelete: undefined,
                taskDeleted: true,
                tasks: [...prevState.tasks].filter(
                    (elem) => elem.id !== taskToDelete.id
                ),
                taskDeleteModalOpen: false,
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                roleDeleteError: true,
                taskDeleteModalOpen: false,
            }));
        }
    };

    const onTaskUpdateChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            selectedTaskForEdit: {
                ...prevState.selectedTaskForEdit,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const onTaskUpdateSubmit = async (e) => {
        e.preventDefault();

        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            taskUpdateModalOpen: false,
            updateType: undefined,
        }));
        try {
            const standardString =
                state.selectedTaskForEdit.standard.toString();
            let serverEntity = {
                id: state.selectedTaskForEdit.id,
                name: state.selectedTaskForEdit.name,
                type: state.selectedTaskForEdit.type,
                label: state.selectedTaskForEdit.label,
                standard: standardString,
                roleId: state.selectedTaskForEdit.roleId,
            };

            await api.put(`${TASKS}`, serverEntity, {
                headers: { Authorization: token },
            });

            const tasksCopy = [...state.tasks].filter(
                (elem) => elem.id !== serverEntity.id
            );
            tasksCopy.push(serverEntity);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                taskUpdated: true,
                selectedTaskForEdit: undefined,
                tasks: [...tasksCopy],
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                taskUpdateError: true,
            }));
        }
    };

    /*
     * ===== CREATE/DELETE Task MODAL FUNCTIONS =====
     */

    const onTaskCreateChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            newTask: {
                ...prevState.newTask,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const onTaskCreateSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            taskCreateModalOpen: false,
            updateType: undefined,
        }));

        try {
            const standardString = state.newTask.standard.toString();
            let serverEntity = {
                id: 0,
                name: state.newTask.name,
                type: state.newTask.type,
                label: state.newTask.label,
                standard: standardString,
                roleId: state.selectedRole.id,
            };
            const created = await api.post(`${TASKS}`, serverEntity, {
                headers: { Authorization: token },
            });
            const tasksCopy = [...state.tasks].filter(
                (elem) => elem.id !== serverEntity.id
            );
            tasksCopy.push(created.data);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                taskCreated: true,
                selectedTaskForEdit: undefined,
                newTask: {},
                tasks: [...tasksCopy],
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                taskCreateError: true,
                taskCreateModalOpen: false,
            }));
        }
    };

    const onTaskDeleteOpen = async (rowData, isArchivedObj, affectedShifts) => {
        if (isArchivedObj.bool === true) {
            setState((prevState) => ({
                ...prevState,
                taskDeleteModalOpen: true,
                taskToDelete: rowData,
                rowData: undefined,
            }));
        } else {
            // don't unset rowData because it is needed by next modal
            setState((prevState) => ({
                ...prevState,
                probeTaskChangeOpen: true,
                affectedShifts,
            }))
        }
    };

    const onArchiveProbeModalSubmit = () => {
        const openModalKey = state.updateType === "create" ? 
                          "taskCreateModalOpen"         :
                          state.updateType === "update" ?
                          "taskUpdateModalOpen"         :
                          state.updateType === "delete" ?
                          "taskDeleteModalOpen"         :
                          null;

        setState((prevState) => ({
            ...prevState,
            [openModalKey]: true,
            selectedTaskForEdit: {...prevState.rowData}, // pass rowData from table on to next modal
            taskToDelete: {...prevState.rowData},
            probeTaskChangeOpen: false,
        }));
    }

    /**
     * ===== END TASK MODAL FUNCTIONS =====
     */

    /**
     * ===== Role Change & Modal Functions =====
     */

    const handleRoleCreateModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            roleCreateModalOpen: false,
            newRole: {},
        }));
    };

    const onRoleCreateChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            newRole: {
                ...prevState.newRole,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const onRoleCreateSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            roleCreateModalOpen: false,
        }));

        try {
            let serverEntity = {
                id: 0,
                name: state.newRole.name,
                siteId: currentSite.id,
            };
            const resp = await api.post(`${ROLES}`, serverEntity, {
                headers: { Authorization: token },
            });
            getRoles(currentSite.id);
            const rolesCopy = [...state.roles].filter(
                (elem) => elem.id !== serverEntity.id
            );
            rolesCopy.push(resp.data);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                roleCreated: true,
                newRole: {},
                roles: [...rolesCopy],
            }));
        } catch (err) {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                roleCreateError: true,
            }));
        }
    };

    const handleRoleDeleteModalClose = () => {
        setState((prevState) => ({
            ...prevState,
            roleDeleteModalOpen: false,
        }));
    };

    const onRoleDeleteSubmit = async (e) => {
        e.preventDefault();

        try {
            await api.delete(`${ROLES}`, {
                headers: { Authorization: token },
                data: state.selectedRole,
            });
            setState((prevState) => ({
                ...prevState,
                roleDeleteModalOpen: false,
                roleDeleted: true,
                selectedRole: null,
            }));
        } catch (err) {
            setState((prevState) => ({
                ...prevState,
                roleDeleteModalOpen: false,
                roleDeleteError: true,
            }));
        }
    };

    const onRoleAdd = () => {
        setState((prevState) => ({
            ...prevState,
            roleCreateModalOpen: true,
        }));
    };

    const onRoleDeleteOpen = async (e) => {
        e.preventDefault();

        setState((prevState) => ({
            ...prevState,
            roleDeleteModalOpen: true,
        }));
    };

    const onChangeRole = async (e) => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        const newRole = e.target.value;
        getSelectedPages(newRole.id);
        const newTasks = await getTasks(newRole.id);

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            tasks: newTasks === undefined || newTasks === null ? [] : newTasks,
        }));
    };

    return (
        <div className={classes.root}>
            <Container justify="center">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        item
                        className={[classes.grid, classes.topPad].join(" ")}
                    >
                        <RoleSelect
                            roles={state.roles}
                            selectedRole={state.selectedRole}
                            onAdd={onRoleAdd}
                            onDelete={onRoleDeleteOpen}
                            onChangeRole={onChangeRole}
                        />
                    </Grid>
                    <Grid item className={classes.grid}>
                        <MaterialTable
                            title="Role Maintenance"
                            columns={[...columns]}
                            data={[...state.tasks]}
                            isLoading={state.isLoading}
                            icons={tableIcons}
                            options={{
                                exportButton: true,
                                pageSize: 10,
                                headerStyle: {
                                    backgroundColor: "#eee",
                                    fontWeight: "bold",
                                },
                            }}
                            actions={[
                                {
                                    icon: forwardRef((props, ref) => (
                                        <Edit {...props} ref={ref} />
                                    )),
                                    tooltip: "Edit Task",
                                    onClick: (e, rowData) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            rowData: {...rowData},
                                            updateType: "update",
                                            serverUpdate: !prevState.serverUpdate
                                        }))
                                    },
                                },
                                {
                                    icon: forwardRef((props, ref) => (
                                        <DeleteOutlinedIcon
                                            {...props}
                                            ref={ref}
                                        />
                                    )),
                                    tooltip: "Delete Task",
                                    onClick: (e, rowData) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            rowData: {...rowData},
                                            updateType: "delete",
                                            serverUpdate: !prevState.serverUpdate
                                        })),
                                },
                                {
                                    icon: forwardRef((props, ref) => (
                                        <AddBox {...props} ref={ref} />
                                    )),
                                    tooltip: "Add Task",
                                    onClick: (e, rowData) =>
                                        setState((prevState) => ({
                                                ...prevState,
                                                rowData: {...rowData},
                                                updateType: "create",
                                                serverUpdate: !prevState.serverUpdate
                                            })),
                                    isFreeAction: true,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item className={classes.grid}>
                        <PageList
                            currentPages={state.selectedPages}
                            allPages={state.allPages}
                            onSubmit={onSubmitPageList}
                        />
                    </Grid>
                </Grid>
            </Container>
            {/* Task Update Modal */}
            <TaskUpdateModal
                title="Update Task"
                open={state.taskUpdateModalOpen}
                onClose={handleTaskUpdateModalClose}
                task={state.selectedTaskForEdit}
                onChange={onTaskUpdateChange}
                onSubmit={onTaskUpdateSubmit}
            />
            {/* Task Create Modal */}
            <TaskUpdateModal
                title="Create Task"
                open={state.taskCreateModalOpen}
                onClose={handleTaskCreateModalClose}
                task={state.newTask}
                onChange={onTaskCreateChange}
                onSubmit={onTaskCreateSubmit}
            />
            <RoleCreateModal
                open={state.roleCreateModalOpen}
                onClose={handleRoleCreateModalClose}
                role={state.newRole}
                onChange={onRoleCreateChange}
                onSubmit={onRoleCreateSubmit}
            />
            <RoleDeleteModal
                open={state.roleDeleteModalOpen}
                onClose={handleRoleDeleteModalClose}
                onSubmit={onRoleDeleteSubmit}
            />
            <TaskDeleteModal
                open={state.taskDeleteModalOpen}
                onClose={handleTaskDeleteModalClose}
                onSubmit={onTaskDeleteSubmit}
            />
            <ArchiveProbeModal
                open={state.probeTaskChangeOpen} 
                onSubmit={onArchiveProbeModalSubmit}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        probeTaskChangeOpen: false,
                        rowData: undefined,
                    }))
                }
                affectedShifts={{...state.affectedShifts}}
                updateType={state.updateType}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.roleDeleted ||
                    state.roleCreated ||
                    state.taskUpdated ||
                    state.taskCreated ||
                    state.taskDeleted ||
                    state.pageAccessUpdated ||
                    state.taskCreateError ||
                    state.taskUpdateError ||
                    state.taskDeleteError ||
                    state.roleCreateError ||
                    state.roleDeleteError ||
                    state.pageUpdateError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                style={{ zIndex: 2500 }}
                key="A4"
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={
                        state.roleDeleted ||
                        state.roleCreated ||
                        state.taskUpdated ||
                        state.taskCreated ||
                        state.taskDeleted ||
                        state.pageAccessUpdated
                            ? "success"
                            : state.taskCreateError ||
                              state.taskUpdateError ||
                              state.taskDeleteError ||
                              state.roleCreateError ||
                              state.roleDeleteError ||
                              state.pageUpdateError
                            ? "error"
                            : undefined
                    }
                    style={{ zIndex: 2500 }}
                >
                    {state.roleDeleted
                        ? "Role Deleted"
                        : state.roleCreated
                        ? "Role Created"
                        : state.taskUpdated
                        ? "Task Updated"
                        : state.taskCreated
                        ? "Task Created"
                        : state.taskDeleted
                        ? "Task Deleted"
                        : state.pageAccessUpdated
                        ? "Page Access Updated"
                        : state.taskCreateError
                        ? "Error Creating Task"
                        : state.taskUpdateError
                        ? "Error Updating Task"
                        : state.taskDeleteError
                        ? "Error Deleting Task"
                        : state.roleCreateError
                        ? "Error Creating Role"
                        : state.roleDeleteError
                        ? "Error Updating Role"
                        : state.pageUpdateError
                        ? "Error Updating Page Access"
                        : null}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default RoleMaintenance;