import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Zoom, Typography, Modal, Grid, Button, Backdrop } from "@mui/material";
import {List, ListItem, ListItemText} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        height: 500,
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
    buttonSuccess: {
        backgroundColor: "#56f500",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E0E0E0",
        },
    },
    buttonCancel: {
        backgroundColor: "#cfcfcf",
        "&:hover": {
            cursor: "pointer",
            color: "#E0E0E0",
            backgroundColor: "#c51162",
        },
    },
}));

const ArchiveProbeModal = (props) => {
    const classes = useStyles();

    let dataEffectMessage =
        props.updateType === undefined ? 
        "" :
        props.updateType === "update" ?
        " Task Data will be updated for the following months' shifts and production task values. " :
        props.updateType === "create" ?
        " Task data will be appended with zero values for the following months' shifts and production task values. " :
        props.updateType === "delete" ?
        " Task data will be permanently deleted from the following months' shifts and production task values. ": "";

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={props.onSubmit}>
                        <Typography variant="h5" gutterBottom>
                            Shift Production Update will Occur
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Are you sure you want to change this task?
                                    { dataEffectMessage } 
                                    Archive any months from which this task should not be {props.updateType}d.
                                </Typography>
                                {props.affectedShifts && Object.keys(props.affectedShifts)?.length > 0 ?
                                (<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem
                                    key="header"
                                    disableGutters
                                    >
                                        <ListItemText 
                                            primary='month-year, number of shifts, volume of tasks'
                                        />
                                    </ListItem>
                                {Object.keys(props.affectedShifts).map((k) => (
                                    <ListItem
                                    key={k}
                                    disableGutters
                                    >
                                        <ListItemText 
                                            primary={`${props.affectedShifts[k].monthYear}, ${props.affectedShifts[k].affectedShifts}, ${props.affectedShifts[k].taskQuantity}`} 
                                        />
                                    </ListItem>
                                ))}
                                </List>) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    className={[
                                        classes.button,
                                        classes.buttonSuccess,
                                    ].join(" ")}
                                    variant="contained"
                                    type="submit"
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    className={[
                                        classes.button,
                                        classes.buttonCancel,
                                    ].join(" ")}
                                    variant="contained"
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default ArchiveProbeModal;
