import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Grow, Snackbar, Box } from "@mui/material";
// Material UI Icons
import MuiAlert from "@mui/material/Alert";
// Custom
import { tableIcons } from "../../../util/tableIcons";
import api from "../../../api";
import { FORMATTED_SHIFTS } from "../../../api/routes";
// Custom JSX components
import MonthlyLeaderControl from "./MonthlyLeaderControl";
import * as dateUtils from "../../../util/dateUtils/dateUtils";
import * as mtExport from "../../../util/materialTableExport";
import * as models from "../../../util/models";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
    container: {
        marginTop: 50,
    },
}));

export const roleTasksTime = [
    { name: "shiftDate", type: "date", label: "Shift Date" },
    { name: "shiftStart", type: "time", label: "Shift Start" },
    { name: "shiftEnd", type: "time", label: "Shift End" },
    { name: "lunchDuration", type: "number", label: "Lunch Duration" },
];

export const roleTasksOffProd = [
    { name: "comments", type: "textarea", label: "Comments" },
    { name: "breakOffProd", type: "number", label: "Break" },
    { name: "systemDownOffProd", type: "number", label: "System Down" },
    { name: "meetingOffProd", type: "number", label: "Meeting" },
    { name: "trainingOffProd", type: "number", label: "Training" },
    { name: "projectOffProd", type: "number", label: "Project" },
    { name: "miscOffProd", type: "number", label: "Miscellaneous" },
];

const MonthlyLeader = () => {
    const classes = useStyles();
    const { roles, token, tasks, currentSite } = useSelector((state) => ({
        roles: state.rolesReducer.roles,
        token: state.authReducer.token,
        tasks: state.tasksReducer.tasks,
        currentSite: state.sitesReducer.currentSite,
    }));

    const [state, setState] = useState({
        isLoading: false,
        filters: {
            startDate: dateUtils.getFirstDayOfYear(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfYear(
                new Date().toISOString().split("T")[0]
            ),
        },
        serverUpdate: false,
        scores: [],
        roles: [],
    });

    const getScoresCallback = useCallback(
        async (startDate, endDate, roleId) => {
            let scores = await api.get(
                `${FORMATTED_SHIFTS}/role/scorecard/${roleId}`,
                {
                    headers: { Authorization: token },
                    params: { startDate: startDate, endDate: endDate },
                }
            );
            return scores.data;
        },
        [token]
    );

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        // needed this hack to pass current state to getShifts, it was receiving previous state.
        const fetchAPI = async () => {
            const siteRoles = await models.getSiteRoles(token, currentSite.id);

            const selectedRole =
                state.selectedRole !== undefined
                    ? state.selectedRole
                    : siteRoles.data[0];

            const scores = await getScoresCallback(
                state.filters.startDate,
                state.filters.endDate,
                selectedRole.id
            );
            const formattedScores = formatScores(scores);
            setState((prevState) => ({
                ...prevState,
                scores: formattedScores,
                roles: siteRoles.data,
                selectedRole,
            }));
        };
        fetchAPI();

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    }, [
        state.filters.startDate,
        state.filters.endDate,
        tasks,
        state.serverUpdate,
    ]);

    const formatScores = (scores) => {
        const formatted = [];
        for (let userScores of scores) {
            if (userScores.length <= 0) continue;

            const months = { name: userScores[0].name };
            let offset = 0; // this accounts for months that do not have any production data
            for (let i = 0; i < 12; i++) {
                if (
                    i - offset < userScores.length &&
                    dateUtils.monthToInt[userScores[i - offset].month] === i
                ) {
                    const computed = +userScores[i - offset].score * 100.0;
                    months[userScores[i - offset].month.toLowerCase()] =
                        Math.round(computed);
                } else {
                    offset++;
                    months[dateUtils.intToMonth[i].toLowerCase()] = 0.0;
                }
            }
            formatted.push(months);
        }
        return formatted;
    };
    /*
     * ===== SHIFT FILTER FUNCTIONS =====
     */

    const onChangeBack = () => {
        const lastYearDt = +state.filters.startDate.split("-")[0] - 1;

        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: `${lastYearDt}-01-01`,
                endDate: `${lastYearDt}-12-31`,
                serverUpdate: !prevState.serverUpdate,
            },
        }));
    };

    const onChangeForward = () => {
        const lastYearDt = +state.filters.startDate.split("-")[0] + 1;
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: `${lastYearDt}-01-01`,
                endDate: `${lastYearDt}-12-31`,
                serverUpdate: !prevState.serverUpdate,
            },
        }));
    };

    // Role Toggle funciton
    const onChangeRole = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const newRole = e.target.value;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            serverUpdate: !prevState.serverUpdate,
        }));
    };

    // Snackbar Function
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            shiftUpdated: false,
            shiftAdded: false,
            shiftDeleted: false,
            shiftCreateError: false,
            shiftUpdateError: false,
            shiftDeleteError: false,
        }));
    };

    const columns = [
        { title: "Name", field: "name" },
        { title: "January", field: "january" },
        { title: "February", field: "february" },
        { title: "March", field: "march" },
        { title: "April", field: "april" },
        { title: "May", field: "may" },
        { title: "June", field: "june" },
        { title: "July", field: "july" },
        { title: "August", field: "august" },
        { title: "September", field: "september" },
        { title: "October", field: "october" },
        { title: "November", field: "november" },
        { title: "December", field: "december" },
    ];
    const scores = [...state.scores];

    const exportScores = scores.map(Object.values);

    const exportArray = 
        mtExport.exportCSV(
            scores.length <= 0, 
            exportScores, 
            "monthly-leaders-" +
            state.filters.startDate.split("-")[1] +
            "-" +
            state.filters.startDate.split("-")[0]
        );

    return (
        <>
            <Container className={classes.container}>
                <Box mt={1} mb={1}>
                    <MonthlyLeaderControl
                        filters={state.filters}
                        onChangeBack={onChangeBack}
                        onChangeForward={onChangeForward}
                        roles={state.roles}
                        onChangeRole={onChangeRole}
                        selectedRole={state.selectedRole}
                    />
                </Box>
                <Grow in={true} timeout={500}>
                    {tasks === [] ? (
                        <div> Loading...</div>
                    ) : (
                        <Box mb={1}>
                            <MaterialTable
                                title={`Monthly Leaders`}
                                columns={columns}
                                data={scores}
                                isLoading={state.isLoading}
                                icons={tableIcons}
                                options={{
                                    exportButton: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    headerStyle: {
                                        backgroundColor: "#eee",
                                        fontWeight: "bold",
                                    },
                                    exportMenu: exportArray,
                                }}
                            />
                        </Box>
                    )}
                </Grow>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.shiftUpdated ||
                    state.shiftAdded ||
                    state.shiftDeleted ||
                    state.shiftUpdateError ||
                    state.shiftCreateError ||
                    state.shiftDeleteError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
                classes={{ root: { zIndex: 2500 } }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    open={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted ||
                        state.shiftUpdateError ||
                        state.shiftCreateError ||
                        state.shiftDeleteError
                    }
                    severity={
                        state.shiftUpdated ||
                        state.shiftAdded ||
                        state.shiftDeleted
                            ? "success"
                            : state.shiftUpdateError ||
                              state.shiftCreateError ||
                              state.shiftDeleteError
                            ? "error"
                            : undefined
                    }
                    style={{ zIndex: 2500 }}
                    classes={{ root: { zIndex: 2500 } }}
                >
                    {state.shiftUpdated
                        ? "Daily Production Updated"
                        : state.shiftAdded
                        ? "Daily Production Added"
                        : state.shiftDeleted
                        ? "Daily Production Deleted"
                        : state.shiftCreateError
                        ? "Error Creating Daily Production"
                        : state.shiftUpdateError
                        ? "Error Updating Daily Production"
                        : state.shiftDeleteError
                        ? "Error Deleting Daily Production"
                        : null}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default MonthlyLeader;
