import * as types from "./actionTypes";

export default function pagesReducer(
  state = {
    pages: undefined,
    pagesSuccess: false,
    pagesError: null
  },
  action
) {
  switch (action.type) {
    case types.PAGES:
      return {
        ...state,
        pages: action.pages
      };
    case types.PAGES_SUCCESS:
      return {
        ...state,
        pagesSuccess: action.pagesSuccess
      };
    case types.PAGES_ERROR:
      return {
        ...state,
        pagesError: action.pagesError
      };
    default:
      return state;
  }
}
