import * as types from "./actionTypes";
import api from "../../api";
import { ADMIN, USERS } from "../../api/routes";

const sendLoginRequest = async (username, password) => {
    return await api.post(`${ADMIN}/login`, { username, password });
};

export const login = (username, password) => {
    return async (dispatch) => {
        const resp = await sendLoginRequest(username, password);
        const headers = {
            headers: { Authorization: `Bearer ${resp.headers.authorization}` },
        };
        setToken(`Bearer ${resp.headers.authorization}`);
        const r2 = await api.get(`${USERS}/${username}`, headers);
        return r2;
    };
};

export const setToken = (token) => {
    return (dispatch) => dispatch({ type: types.TOKEN, token: token });
};
