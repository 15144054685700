import React, {
    useState,
    forwardRef,
    memo,
    useCallback,
    useEffect,
} from "react";
import { useSelector, shallowEqual } from "react-redux";
import MaterialTable from "@material-table/core";

import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// Material UI Icons
import AddBox from "@mui/icons-material/AddBox";
import Edit from "@mui/icons-material/Edit";

// Custom imports
import { tableIcons } from "../../util/tableIcons";
import api from "../../api";
import { ROLES, USERS, USER_ROLE } from "../../api/routes";
import UserListUpdateModal from "../Modals/UserMaintenanceModals/UserListModals/UserListUpdateModal";
import UserListCreateModal from "../Modals/UserMaintenanceModals/UserListModals/UserListCreateModal";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
}));

const UserList = () => {
    const classes = useStyles();

    const columns = [
        { title: "First Name", field: "fname" },
        { title: "Last Name", field: "lname" },
        { title: "Email", field: "email" },
        { title: "Is Active", field: "isActive" },
        { title: "Role", field: "role" },
    ];

    const { token, sites, currentSite, user } = useSelector(
        (state) => ({
            token: state.authReducer.token,
            sites: state.sitesReducer.sites,
            currentSite: state.sitesReducer.currentSite,
            user: state.userReducer.user,
        }),
        shallowEqual
    );

    const [state, setState] = useState({
        isLoading: false,
        userAdded: false,
        userDeleted: false,
        userUpdated: false,
        userUpdateModalOpen: false,
        userCreateModalOpen: false,
        selectedUserForEdit: {
            id: 0,
            fname: "",
            lname: "",
            email: "",
            passwordHash: "",
            isActive: false,
            supervisor: "",
        },
        newUser: {
            id: 0,
            fname: "",
            lname: "",
            email: "",
            passwordHash: "",
            isActive: false,
            supervisor: "",
        },
        users: [],
        userTable: [],
        serverUpdate: false,
    });

    const getUsers = useCallback(
        async (siteId) =>
            await api.get(`${USERS}/site/${siteId}`, {
                headers: { Authorization: token },
            }),
        [token]
    );

    const getRoles = useCallback(
        async (siteId) => {
            let roles = await api.get(`${ROLES}/site/${siteId}`, {
                headers: { Authorization: token },
            });
            return roles;
        },
        [token]
    );

    const getUserRoles = useCallback(
        async (siteId) => {
            let userRoles = await api.get(`${USER_ROLE}/site/${siteId}`, {
                headers: { Authorization: token },
            });
            return userRoles;
        },
        [token]
    );

    useEffect(() => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const curry = async () => {
            const users = await getUsers(currentSite.id);
            const roles = await getRoles(currentSite.id);
            const userRoles = await getUserRoles(currentSite.id);
            let userTable = [];
            try {
                userTable = users.data.map((user) => {
                    // TODO: an error here signifies that there is a missing user_role record for the site & users & userRoles are different lengths
                    const roleId = userRoles.data.find(
                        (ur) => ur.userId === user.id
                    ).roleId;
                    const role = roles.data.find((r) => r.id === roleId);

                    return { ...user, role: role.name };
                });
            } catch (err) {
                setState((prevState) => ({
                    ...prevState,
                    noUserRoleError: true,
                    noUserRoleUser: user.email,
                }));
                return undefined;
            }

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                users: users.data,
                roles: roles.data,
                userRoles: userRoles.data,
                userTable,
            }));
        };

        curry();
    }, [token, currentSite, state.serverUpdate]);

    // Snackbar Function
    const handleSnackbarClose = (e, reason) => {
        setState((prevState) => ({
            ...prevState,
            userUpdated: false,
            userAdded: false,
            userDeleted: false,
            userUpdateError: false,
            userCreateError: false,
            userDeleteError: false,
        }));
    };

    const handleAlertClose = (event, reason) => {
        setState((prevState) => ({
            ...prevState,
            userUpdated: false,
            userAdded: false,
            userDeleted: false,
            userUpdateError: false,
            userCreateError: false,
            userDeleteError: false,
        }));
    };

    const removeUser = async (user) => {
        try {
            const userEntity = {
                id: user.id,
                fname: user.fname,
                lname: user.lname,
                email: user.email,
                passwordHash: "",
                isActive: user.isActive,
                supervisor: user.supervisor,
            };
            await api.delete(`${USERS}`, {
                headers: { Authorization: token },
                data: userEntity,
            });
            setState((prevState) => {
                return {
                    ...prevState,
                    users: prevState.users.filter((usr) => usr.id !== user.id),
                    userDeleted: true,
                    serverUpdate: !prevState.serverUpdate,
                };
            });
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                userDeleteError: true,
            }));
        }
    };

    /*
     * ===== GENERAL MODAL FUNCTIONS =====
     */
    const renderUserUpdateModal = async (rowData) => {
        const role = state.userRoles.find((ur) => ur.userId === rowData.id);
        setState((prevState) => {
            return {
                ...prevState,
                userUpdateModalOpen: true,
                selectedUserForEdit: { ...rowData, role: role.roleId },
                userAccessLevel: rowData,
            };
        });
    };

    const renderUserCreateModal = () => {
        setState((prevState) => {
            return { ...prevState, userCreateModalOpen: true };
        });
    };

    const handleUpdateModalClose = () => {
        setState((prevState) => {
            return {
                ...prevState,
                userUpdateModalOpen: false,
                selectedUserForEdit: {
                    id: 0,
                    fname: "",
                    lname: "",
                    email: "",
                    passwordHash: "",
                    isActive: false,
                    supervisor: "",
                    role: "",
                },
            };
        });
    };

    const handleCreateModalClose = () => {
        setState((prevState) => {
            return {
                ...prevState,
                userCreateModalOpen: false,
                newUser: {
                    id: 0,
                    fname: "",
                    lname: "",
                    email: "",
                    passwordHash: "",
                    isActive: false,
                    supervisor: "",
                    role: "",
                },
            };
        });
    };

    /*
     * ===== UPDATE USER MODAL FUNCTIONS =====
     */
    const onUserUpdateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedUserForEdit: {
                    ...prevState.selectedUserForEdit,
                    [e.target.name]: e.target.value,
                },
            };
        });
    };

    const onUserUpdateSubmit = async (e) => {
        e.preventDefault();

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true,
                userUpdateModalOpen: false,
            };
        });

        try {
            const userEntity = {
                id: state.selectedUserForEdit.id,
                fname: state.selectedUserForEdit.fname,
                lname: state.selectedUserForEdit.lname,
                email: state.selectedUserForEdit.email,
                passwordHash: state.selectedUserForEdit.passwordHash || "",
                isActive: state.selectedUserForEdit.isActive,
                supervisor: state.selectedUserForEdit.supervisor,
            };

            await api.put(
                `${USERS}/${state.selectedUserForEdit.role}`,
                userEntity,
                { headers: { Authorization: token } }
            );

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                userUpdated: true,
                users: prevState.users.map((userArray) =>
                    userArray.id === state.selectedUserForEdit.id
                        ? state.selectedUserForEdit
                        : userArray
                ),
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                userUpdateError: true,
            }));
        }
    };

    const handleSiteUpdateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedUserForEdit: {
                    ...prevState.selectedUserForEdit,
                    dfltSiteId: e.target.value,
                },
            };
        });
    };

    const handleSupervisorUpdateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedUserForEdit: {
                    ...prevState.selectedUserForEdit,
                    supervisor: e.target.value,
                },
            };
        });
    };

    const handleIsActiveUpdateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedUserForEdit: {
                    ...prevState.selectedUserForEdit,
                    isActive: e.target.checked,
                },
            };
        });
    };

    /*
     * ===== CREATE USER MODAL FUNCTIONS =====
     */
    const onUserCreateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                newUser: {
                    ...prevState.newUser,
                    [e.target.name]: e.target.value,
                },
            };
        });
    };

    const onUserCreateSubmit = async (e) => {
        e.preventDefault();

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true,
                userCreateModalOpen: false,
            };
        });
        try {
            const newUser = { ...state.newUser };
            const roleId = state.newUser.role;
            delete newUser.role;
            const response = await api.post(
                `${USERS}/${currentSite.id}/${roleId}`,
                newUser,
                { headers: { Authorization: token } }
            );
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                userAdded: true,
                users: [...state.users, response.data],
                serverUpdate: !prevState.serverUpdate,
            }));
        } catch {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                userCreateError: true,
            }));
        }
    };

    const handleSiteCreateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                newUser: {
                    ...prevState.newUser,
                    dfltSiteId: e.target.value,
                },
            };
        });
    };

    const handleSupervisorCreateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                newUser: {
                    ...prevState.newUser,
                    supervisor: e.target.value,
                },
            };
        });
    };

    const handleIsActiveCreateChange = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                newUser: {
                    ...prevState.newUser,
                    isActive: e.target.checked,
                },
            };
        });
    };

    return (
        <div className={classes.root}>
            <Container key="A1">
                <MaterialTable
                    title={`User List - ${currentSite.name}`}
                    columns={columns}
                    data={state.userTable}
                    isLoading={state.isLoading}
                    icons={tableIcons}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50, 100],
                        exportButton: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: "#eee",
                            fontWeight: "bold",
                        },
                    }}
                    actions={[
                        {
                            icon: forwardRef((props, ref) => (
                                <Edit {...props} ref={ref} />
                            )),
                            tooltip: "Edit User",
                            onClick: (e, rowData) =>
                                renderUserUpdateModal(rowData),
                        },
                        {
                            icon: forwardRef((props, ref) => (
                                <AddBox {...props} ref={ref} />
                            )),
                            tooltip: "Add User",
                            onClick: (e) => renderUserCreateModal(),
                            isFreeAction: true,
                        },
                    ]}
                    editable={{
                        onRowDelete: (userToDelete) => removeUser(userToDelete),
                    }}
                />
            </Container>
            <UserListUpdateModal
                open={state.userUpdateModalOpen}
                onClose={handleUpdateModalClose}
                user={state.selectedUserForEdit}
                sites={sites}
                supervisors={state.users}
                roles={state.roles}
                onChange={onUserUpdateChange}
                onSubmit={onUserUpdateSubmit}
                handleSiteChange={handleSiteUpdateChange}
                handleSupervisorChange={handleSupervisorUpdateChange}
                handleIsActiveChange={handleIsActiveUpdateChange}
                key="A2"
            />
            <UserListCreateModal
                open={state.userCreateModalOpen}
                onClose={handleCreateModalClose}
                user={state.newUser}
                sites={sites}
                supervisors={state.users}
                roles={state.roles}
                onChange={onUserCreateChange}
                onSubmit={onUserCreateSubmit}
                handleSiteChange={handleSiteCreateChange}
                handleSupervisorChange={handleSupervisorCreateChange}
                handleIsActiveChange={handleIsActiveCreateChange}
                key="A3"
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={
                    state.userUpdated ||
                    state.userAdded ||
                    state.userDeleted ||
                    state.userCreateError ||
                    state.userUpdateError ||
                    state.userDeleteError ||
                    state.noUserRoleError ||
                    state.noRoleError
                }
                autoHideDuration={null}
                onClose={handleSnackbarClose}
                key="A4"
                style={{ zIndex: 2500 }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={
                        state.userUpdated ||
                        state.userAdded ||
                        state.userDeleted
                            ? "success"
                            : state.userUpdateError ||
                              state.userCreateError ||
                              state.userDeleteError ||
                              state.noUserRoleError ||
                              state.noRoleError
                            ? "error"
                            : undefined
                    }
                    open={
                        state.userUpdated ||
                        state.userAdded ||
                        state.userDeleted ||
                        state.userCreateError ||
                        state.userUpdateError ||
                        state.userDeleteError ||
                        state.noUserRoleError ||
                        state.noRoleError
                    }
                    style={{ zIndex: 2500 }}
                >
                    {state.userUpdated
                        ? "User Updated"
                        : state.userAdded
                        ? "User Created"
                        : state.userDeleted
                        ? "User Deleted"
                        : state.userUpdateError
                        ? "Error Updating User"
                        : state.userCreateError
                        ? "Error Creating User"
                        : state.userDeleteError
                        ? "Error Deleting User"
                        : state.noUserRoleError
                        ? `This site has a user missing a roleId. Please contact your administrator. ${state.noUserRoleUser}`
                        : state.noRoleError
                        ? "This Role does not exist. Please contact your administrator."
                        : null}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default memo(UserList);
