import * as types from "./actionTypes";

export default function tasksReducer(
  state = {
    tasks: undefined,
    tasksSuccess: false,
    tasksError: null
  },
  action
) {
  switch (action.type) {
    case types.TASKS:
      return {
        ...state,
        tasks: action.tasks
      };
    case types.TASKS_SUCCESS:
      return {
        ...state,
        tasksSuccess: action.tasksSuccess
      };
    case types.TASKS_ERROR:
      return {
        ...state,
        tasksError: action.tasksError
      };
    default:
      return state;
  }
}
