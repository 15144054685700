import * as types from "./actionTypes";

const setSites = (sites) => {
    return {
        type: types.SITES,
        sites,
    };
};

const _setCurrentSite = (site) => {
    return {
        type: types.CURRENT_SITE,
        currentSite: site,
    };
};

const setSitesSuccess = (sitesSuccess) => {
    return {
        type: types.SITES_SUCCESS,
        sitesSuccess,
    };
};

const setSitesError = (sitesError) => {
    return {
        type: types.SITES_ERROR,
        sitesError,
    };
};

export const getSites = (siteData) => {
    return async (dispatch) => {
        try {
            // const response = await sendSitesRequest();
            // dispatch(setSites(response.data));
            dispatch(setSites(siteData));
            dispatch(setSitesSuccess(true));
        } catch (err) {
            dispatch(setSitesError(err));
        }
    };
};

export const setCurrentSite = (site) => {
    return async (dispatch) => {
        try {
            dispatch(_setCurrentSite(site));
        } catch (err) {
            dispatch(setSitesError(err));
        }
    };
};
