import React from "react";
import { Grow, Grid, Card, CardHeader, CardContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Chart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import * as graphUtils from "../../util/graphUtils/graphUtils";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        backgroundColor: "#FFF",
        color: "#FFF",
        // marginBottom: 15,
        alignItems: "center",
    },
    cardHeaderTitle: {
        marginLeft: 10,
        marginTop: 5,
        fontWeight: 500,
    },
    cardHeader: {
        borderBottom: "1px solid #EEE",
    },
}));

const UserDashboard = (props) => {
    const classes = useStyles();

    /*------------- multiple radial bar ----------------*/
    const options1 = {
        labels: ["Off Production", "Production"],
        colors: ["#3f51b5", "#002984"],
        plotOptions: {
            radialBar: {
                hollow: {
                    size: "70%",
                },
                dataLabels: {
                    name: {
                        fontSize: "22px",
                    },
                    value: {
                        fontSize: "16px",
                    },
                    total: {
                        show: true,
                        label: "Production",
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below uses a custom formatter function
                            return w.config.series[1] + "%";
                        },
                    },
                },
            },
        },
    };

    const computePercentages = (shifts) => {
        let prodSum = 0.0,
            offProdSum = 0.0,
            hoursWorked = 0.0;
        for (let shift of shifts) {
            prodSum += parseFloat(
                shift.find((attr) => attr.name === "hoursEarned").value
            );
            offProdSum += parseFloat(
                shift.find((attr) => attr.name === "offProd").value
            );
            hoursWorked += parseFloat(
                shift.find((attr) => attr.name === "hoursWorked").value
            );
        }
        prodSum = Math.round((prodSum / hoursWorked) * 100.0);
        offProdSum = Math.round((offProdSum / hoursWorked) * 100.0);
        // conditional logic while waiting on data
        if (Object.is(NaN, prodSum) || Object.is(NaN, offProdSum))
            return [0, 0];
        else return [offProdSum, prodSum];
    };

    const series1 =
        props.shifts !== undefined
            ? computePercentages(props.shifts)
            : props.datePercentages !== undefined
            ? props.datePercentages
            : [0, 0];

    /*------------- line ----------------*/
    const graphData = props.shifts
        ? graphUtils.shiftToLineGraph(props.shifts)
        : graphUtils.formatShiftPercentages(props.shiftPercentages);

    const lineData = {
        labels: graphData.labels,
        datasets: [
            {
                label: "Daily Productivity",
                fill: true,
                lineTension: 0.1,
                backgroundColor: "#757de8",
                borderColor: "#3f51b5",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#3f51b5",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#3f51b5",
                pointHoverBorderColor: "#3f51b5",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: graphData.data,
            },
        ],
    };

    return (
        <Card>
            <CardHeader
                title="Month to Date Productivity"
                classes={{
                    root: classes.cardHeader,
                    title: classes.cardHeaderTitle,
                }}
            />
            <CardContent>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={12} md={6}>
                        <Chart
                            options={options1}
                            series={series1}
                            type="radialBar"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Line data={lineData} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default UserDashboard;
