import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Material UI Icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";

// Custom
import { login, setToken } from "../store/auth/authActions";
import { getUser } from "../store/user/userActions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
        height: "calc(100vh - 331px)",
    },
    formContainer: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    loginButton: {
        marginTop: "1.5rem",
        backgroundColor: "#448aff",
        fontWeight: "bold",
        color: "#e4f3ff",
        "&:hover": {
            backgroundColor: "#448AFF",
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#448AFF",
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { user } = useSelector((state) => ({
        user: state.userReducer.user,
    }));

    const [state, setState] = useState({ loading: false });

    const onChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        try {
            const userRes = await dispatch(
                login(state.j_username, state.j_password)
            );
            const user = userRes.data;
            dispatch(setToken(userRes.config.headers.Authorization));
            dispatch(getUser(user));
            history.push("/daily-production");
        } catch (err) {
            setState((prevState) => ({
                ...prevState,
                loading: false,
                loginError: true,
            }));
        }
    };

    if (user) return <Redirect to="/" />;
    return (
        <div className={classes.root}>
            <Fade in={true} timeout={500}>
                <Container component="main" maxWidth="xs">
                    <div className={classes.formContainer}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon
                                style={{ color: "#e4f3ff" }}
                                fontSize="small"
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in with your Credentials
                        </Typography>
                        <form
                            autoComplete="off"
                            className={classes.form}
                            onSubmit={onSubmit}
                        >
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Username"
                                id="j_username"
                                value={state.j_username || ""}
                                error={state.loginError}
                                helperText={
                                    state.loginError
                                        ? "Invalid Credentials"
                                        : ""
                                }
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                label="Password"
                                id="j_password"
                                value={state.j_password || ""}
                                type="password"
                                error={state.loginError}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.loginButton}
                            >
                                {state.loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    "Sign In"
                                )}
                            </Button>
                        </form>
                    </div>
                </Container>
            </Fade>
        </div>
    );
};

export default Login;
