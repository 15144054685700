import React from "react";
import {
    TextField,
    TextareaAutosize,
    Popover,
    Button,
    Box,
    Typography,
} from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

export const renderTextField = (
    shift,
    indx,
    onChange,
    disabled = false,
    error = false
) => {
    switch (shift.type) {
        case "number":
            return (
                <TextField
                    key={"A" + indx}
                    required
                    variant="standard"
                    type="number"
                    name={shift.name}
                    label={shift.label}
                    placeholder={shift.label}
                    onChange={onChange}
                    value={shift?.value}
                    disabled={disabled}
                />
            );
        case "text":
            return (
                <TextField
                    key={"A" + indx}
                    required
                    fullWidth
                    variant="standard"
                    name={shift.name}
                    label={shift.label}
                    placeholder={shift.label}
                    onChange={onChange}
                    value={shift?.value || ""}
                    disabled={disabled}
                />
            );
        case "textarea":
            return (
                <TextareaAutosize
                    key={"A" + indx}
                    maxRows={10}
                    minRows={4}
                    inputprops={{ resize: "both", color: "red" }}
                    name={shift.name}
                    aria-label={shift.label}
                    placeholder={shift.label}
                    value={shift?.value || ""}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        case "date":
            return (
                <TextField
                    key={"A" + indx}
                    required
                    fullWidth
                    variant="standard"
                    type="date"
                    name={shift?.name}
                    label={shift.label}
                    onChange={onChange}
                    value={
                        shift?.value || new Date().toISOString().split("T")[0]
                    }
                    disabled={disabled}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error}
                    helperText={
                        error ? "Duplicate Shift Prohibited" : undefined
                    }
                />
            );
        case "time":
            return (
                <TextField
                    key={"A" + indx}
                    required
                    fullWidth
                    variant="standard"
                    type="time"
                    name={shift.name}
                    label={shift.label}
                    onChange={onChange}
                    value={
                        shift?.value ||
                        `${new Date().getUTCHours()}:${new Date().getUTCHours()}`
                    }
                    disabled={disabled}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        default:
            return null;
    }
};

const formatTime = (timeStr) => {
    const splt = timeStr.split(":");
    let hour = +splt[0] % 12;
    hour = hour === 0 ? 12 : hour;
    const amPm = +splt[0] > 12 ? "PM" : "AM";
    return `${hour.toString()}:${splt[1]} ${amPm}`;
};

const formatDateTime = (dateStr) => {
    const splt = dateStr.split("T");
    const dt = splt[0];
    const tm = formatTime(splt[1]);
    return dt + " " + tm;
};

export const tableRender = (cell) => {
    switch (cell.type) {
        case "number":
            return cell.value.toString();
        case "text":
            return cell.value;
        case "date":
            return cell.value;
        case "time":
            return formatTime(cell.value);
        case "datetime":
            return formatDateTime(cell.value);
        case "select":
            return cell.value;
        case "textarea":
            return (
                // TODO: this is causing the delete error
                <PopupState variant="popover" popupId="comment-popup-popover">
                    {(popupState) => (
                        <div>
                            <Button
                                variant="contained"
                                {...bindTrigger(popupState)}
                                style={{
                                    backgroundColor: "#33a3ff",
                                    color: "white",
                                }}
                            >
                                Comments
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box p={2}>
                                    <Typography>{cell.value}</Typography>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            );
        default:
            return "";
    }
};

export const getShiftDefaults = (type) => {
    switch (type) {
        case "number":
            return 0;
        case "text":
            return "";
        case "date":
            let d = new Date().toISOString().split("T")[0];
            return d;
        case "time":
            let tm = new Date().toISOString().split("T")[1];
            tm = tm.replace("Z", "");
            return formatTime(tm);
        case "datetime":
            const dt = new Date().toISOString();
            return formatDateTime(dt);
        case "select":
            return "";
        default:
            return "";
    }
};
