import api from "../api";
import { ARCHIVE, FETCH, ROLES, FORMATTED_SHIFTS } from "../api/routes";

export const getFetch = async (token) => {
    return await api.get(`${FETCH}`, { headers: { Authorization: token } });
};

export const getFetchSite = async (token, siteId) => {
    return await api.get(`${FETCH}/site/${siteId}`, {
        headers: { Authorization: token },
    });
};

export const getSiteRoles = async (token, siteId) =>
    await api.get(`${ROLES}/site/${siteId}`, {
        headers: { Authorization: token },
    });

export const getMissingShiftsByDay = async (
    token,
    roleId,
    startDate,
    endDate
) =>
    await api.get(`${FORMATTED_SHIFTS}/missing/role/${roleId}/day`, {
        headers: { Authorization: token },
        params: { startDate, endDate },
    });

export const getMissingShiftsByUser = async (
    token,
    roleId,
    startDate,
    endDate
) =>
    await api.get(`${FORMATTED_SHIFTS}/missing/role/${roleId}/user`, {
        headers: { Authorization: token },
        params: { startDate, endDate },
    });

export const getShiftArchiveProbe = async (token, roleId, taskId) =>
    await api.get(`${ARCHIVE}/${roleId}/task/${taskId}/probe`, {
        headers: { Authorization: token },
    })
