import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Zoom, Typography, Modal, Grid, Button, Backdrop } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        width: 400,
        height: 200,
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "100%",
    },
}));

const MonthArchiveModal = (props) => {
    const classes = useStyles();

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className={classes.center}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={props.open}>
                <div className={classes.modal}>
                    <form onSubmit={props.onSubmit}>
                        <Typography variant="h6" gutterBottom>
                            Archive Daily Production
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Are you sure you want to archive all{" "}
                                    {props.role ? props.role.name : ""} shifts
                                    from{" "}
                                    {props.monthRange
                                        ? props.monthRange.startDate
                                        : ""}{" "}
                                    to{" "}
                                    {props.monthRange
                                        ? props.monthRange.endDate
                                        : ""}
                                    ?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={classes.button}
                                >
                                    OK
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={props.onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Zoom>
        </Modal>
    );
};

export default MonthArchiveModal;
