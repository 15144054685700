import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
// Material UI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Grid,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Typography,
    Badge,
    Card,
    CardHeader,
    CardContent,
} from "@mui/material";
import * as dateUtils from "../../../util/dateUtils/dateUtils";
import * as models from "../../../util/models";
import MissingShiftPageControl from "./MissingShiftPageControl";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
        root: {
            margin: theme.spacing(2),
        },
    },
    grid: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    topPad: {
        paddingBottom: 10,
        margin: 5,
    },
    accordionGreen: {
        backgroundColor: "#81c784",
    },
    cardHeaderTitle: {
        marginLeft: 10,
        marginTop: 5,
        fontWeight: 500,
    },
    cardHeader: {
        borderBottom: "1px solid #EEE",
    },
    customBadge: {
        backgroundColor: "#F50057",
        color: "white",
    },
}));

const MissingShift = () => {
    const classes = useStyles();
    const { token, currentSite, roles } = useSelector((state) => ({
        token: state.authReducer.token,
        currentSite: state.sitesReducer.currentSite,
        roles: state.rolesReducer.roles,
    }));

    const [state, setState] = useState({
        isLoading: false,
        selectedRole: undefined,
        dayMissingShifts: undefined,
        userMissingShifts: undefined,
        isDayMissingShifts: true, // IF true = dayMissingShifts ELSE false = userMissingShifts
        filters: {
            startDate: dateUtils.getFirstDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
            endDate: dateUtils.getLastDayOfMonth(
                new Date().toISOString().split("T")[0]
            ),
        },
        selectedDays: dateUtils.daysOfWeek,
    });

    useEffect(() => {
        // page won't render at the top on its own without this
        window.scrollTo(0, 0);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        
        const fetchAPI = async () => {
            const siteRoles = await models.getSiteRoles(token, currentSite.id);
            
            const selectedRole =
                state.selectedRole !== undefined ? 
                state.selectedRole : siteRoles.data[0];

            Promise.all([
                models.getMissingShiftsByDay(
                    token,
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
                models.getMissingShiftsByUser(
                    token,
                    selectedRole.id,
                    state.filters.startDate,
                    state.filters.endDate
                ),
            ]).then((values) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    selectedRole,
                    dayMissingShifts: values[0].data,
                    userMissingShifts: values[1].data,
                    roles: siteRoles.data
                }));
            });
        };

        fetchAPI();
    }, [state.filters.startDate, state.filters.endDate, state.selectedRole]);

    /*
     * ===== MANAGER PAGE CONTROL FUNCTIONS =====
     */

    const onChangeBack = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthBack(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthBackLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeForward = () => {
        setState((prevState) => ({
            ...prevState,
            filters: {
                startDate: dateUtils.shiftMonthForward(
                    prevState.filters.startDate
                ),
                endDate: dateUtils.shiftMonthForwardLastDay(
                    prevState.filters.startDate
                ),
            },
        }));
    };

    const onChangeRole = async (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const newRole = e.target.value;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            selectedRole: newRole,
            // serverUpdate: !prevState.serverUpdate,
        }));
    };

    const onChangeDays = async (e) => {
        e.preventDefault();
        const {
            target: { value },
        } = e;
        setState((prevState) => ({
            ...prevState,
            selectedDays: typeof value === "string" ? value.split(",") : value,
        }));
    };

    /*
     * ===== DATE FORMAT / RENDERING FUNCTIONS =====
     */

    const formattedDate = state.filters.startDate;
    const formatDate = (dt, index) => {
        const day = index + 1; // zero based
        const arr = dt.split("-");
        return arr[0] + "-" + arr[1] + "-" + day;
    };
    const renderDayMissingShifts = (shifts, indx) => {
        const date =
            shifts.length !== 0
                ? shifts[0].date
                : formatDate(formattedDate, indx);
        const day = shifts.length !== 0 ? shifts[0].day : null;
        return date + " " + day;
    };
    const renderUserMissingShifts = (shifts) => {
        const name = shifts.length !== 0 ? shifts[0].name : null;
        return name;
    };

    // the filter removes records that display as "null" or missing data when displayed
    // this could be made better to still display data where no shift's are missing, but
    // as of now the value add doesn't seem worth it
    const missingShifts = state.isDayMissingShifts
        ? state.dayMissingShifts === undefined
            ? undefined
            : state.dayMissingShifts
                  .map((dateList) =>
                      dateList.filter((elem) =>
                          state.selectedDays.includes(elem.day)
                      )
                  )
                  .filter((el) => el.length !== 0)
        : state.userMissingShifts === undefined
        ? undefined
        : state.userMissingShifts
              .map((userList) =>
                  userList.filter((elem) =>
                      state.selectedDays.includes(elem.day)
                  )
              )
              .filter((el) => el.length !== 0);

    return (
        <div className={classes.root}>
            <Container
                container="true"
                spacing={0}
                direction="column"
                alignitems="center"
                justify="center"
            >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        item
                        className={[classes.grid, classes.topPas].join(" ")}
                    >
                        <MissingShiftPageControl
                            filters={state.filters}
                            onChangeBack={onChangeBack}
                            onChangeForward={onChangeForward}
                            roles={state.roles}
                            onChangeRole={onChangeRole}
                            selectedRole={state.selectedRole}
                            toggleGroupBy={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    isDayMissingShifts:
                                        !prevState.isDayMissingShifts,
                                }))
                            }
                            isLoading={state.isLoading}
                            isDayMissingShifts={state.isDayMissingShifts}
                            onChangeDays={onChangeDays}
                            selectedDays={state.selectedDays}
                        />
                    </Grid>
                    <Grid
                        item
                        className={[classes.grid, classes.topPad].join(" ")}
                    >
                        <Card>
                            <CardHeader
                                title="Missing Shifts"
                                subheader={
                                    state.isDayMissingShifts
                                        ? "by Date"
                                        : "by User"
                                }
                                classes={{
                                    root: classes.cardHeader,
                                    title: classes.cardHeaderTitle,
                                    subheader: classes.cardHeaderTitle,
                                }}
                            />
                            <CardContent>
                                {missingShifts !== undefined
                                    ? missingShifts.map((shifts, indx) => (
                                          <Accordion
                                              key={"x" + indx}
                                              disabled={shifts.length === 0}
                                          >
                                              <AccordionSummary
                                                  expandIcon={
                                                      <Badge
                                                          classes={{
                                                              badge: classes.customBadge,
                                                          }}
                                                          badgeContent={
                                                              shifts.length
                                                          }
                                                      >
                                                          <ExpandMoreIcon />
                                                      </Badge>
                                                  }
                                                  aria-controls="panel1a-content"
                                                  id="panel-header"
                                                  className={
                                                      shifts.length === 0
                                                          ? classes.accordionGreen
                                                          : null
                                                  }
                                              >
                                                  <Typography>
                                                      {state.isDayMissingShifts
                                                          ? renderDayMissingShifts(
                                                                shifts,
                                                                indx
                                                            )
                                                          : renderUserMissingShifts(
                                                                shifts
                                                            )}
                                                  </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                  <List dense={true}>
                                                      {shifts.map(
                                                          (shift, indx) => (
                                                              <ListItem
                                                                  key={
                                                                      "i" + indx
                                                                  }
                                                              >
                                                                  <ListItemText
                                                                      dense="true"
                                                                      primary={
                                                                          state.isDayMissingShifts
                                                                              ? shift.name
                                                                              : shift.day +
                                                                                " " +
                                                                                shift.date
                                                                      }
                                                                  />
                                                              </ListItem>
                                                          )
                                                      )}
                                                  </List>
                                              </AccordionDetails>
                                          </Accordion>
                                      ))
                                    : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default MissingShift;
