import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setToken } from "../store/auth/authActions";

import makeStyles from "@mui/styles/makeStyles";
import {
    AppBar,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    IconButton,
    Typography,
} from "@mui/material";
// Material UI Icons
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import PowerSettings from "@mui/icons-material/PowerSettingsNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// Custom
import { logout } from "../store/logout/logoutActions";
import { setCurrentSite } from "../store/sites/sitesActions";
import { setTasks } from "../store/tasks/tasksActions";
import { setRoles } from "../store/roles/rolesActions";
import ToggleSiteModal from "./Modals/ToggleSiteModal";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
            fontSize: ".9rem",
        },
    },
    seeItTitle: {
        color: "#e4f3ff",
        fontWeight: "bold",
    },
    menuButton: {
        color: "#333",
        marginRight: theme.spacing(3),
    },
    username: {
        color: "#333",
        fontSize: ".9rem",
        fontWeight: "bold",
    },
    appBar: {
        // width: `calc(100% - ${drawerWidth}px)`,
        width: "100%",
        marginLeft: drawerWidth,
        background: "#448AFF",
        zIndex: 2000,
    },
}));

const Navbar = ({ drawerOpen, user, setDrawerOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setOpenModal] = useState(false);

    const { sites, currentSite } = useSelector((state) => ({
        sites: state.sitesReducer.sites,
        currentSite: state.sitesReducer.currentSite,
    }));

    const handleMenuClick = (e) => setAnchorEl(e.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleLogout = async () => {
        handleMenuClose();
        dispatch(setToken(""));
        await dispatch(logout());
        history.push("/login");
    };

    const handleSiteChange = (site) => {
        dispatch(setCurrentSite(site));
        dispatch(setRoles(undefined));
        dispatch(setTasks(undefined));
        setOpenModal(false);
        history.push("/daily-production");
    };

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" noWrap className={classes.title}>
                        <span className={classes.seeItTitle}>
                            Performance Tracking
                        </span>{" "}
                        <span style={{ color: "#333" }}>
                            {" "}
                            Application Suite
                        </span>
                    </Typography>
                    {user &&
                    (user.email === "gutscdav000@gmail.com" ||
                        user.email === "ann.hunter@hunterhp.com" ||
                        user.email === "eileen.romps@hunterhp.com") ? (
                        <IconButton
                            aria-label="switch site"
                            edge="end"
                            onClick={() => setOpenModal(true)}
                            size="large"
                        >
                            <AccountTreeIcon />
                        </IconButton>
                    ) : null}
                    {user && (
                        <IconButton
                            edge="end"
                            onClick={handleMenuClick}
                            size="large"
                        >
                            <PersonIcon />
                            <ExpandMore fontSize="small" />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                autoFocus={false}
                style={{ zIndex: 2500 }}
            >
                <ListItem divider={true}>
                    <ListItemText
                        primary={
                            <Typography>
                                Signed in as:{" "}
                                <span
                                    style={{ fontWeight: "bold" }}
                                >{`${user?.fname} ${user?.lname}`}</span>
                            </Typography>
                        }
                    />
                </ListItem>
                <ListItem divider={true}>
                    <ListItemText
                        primary={
                            <Typography>
                                Viewing Site:{" "}
                                <span
                                    style={{ fontWeight: "bold" }}
                                >{`${currentSite?.name}`}</span>
                            </Typography>
                        }
                    />
                </ListItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <PowerSettings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Menu>
            <ToggleSiteModal
                open={modalOpen}
                currentSite={currentSite}
                sites={sites}
                onClose={() => setOpenModal(false)}
                onSubmit={handleSiteChange}
                // handleSiteChange={}
            />
        </>
    );
};

export default Navbar;
